import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    blkDarkBlue: {
        color: '#0d1523',
    },
}));

export const BlkCircularProgress = () => {
    const classes = useStyles();

    return <CircularProgress className={classes.blkDarkBlue} />;
};
