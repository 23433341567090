import React, { Component } from 'react';
import ReactGA from 'react-ga';
import menuItems from '../Items.json';
import SideNav from '../navigation/SideNav';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import APIFetcher from '../remoteData/APIFetcher';
import { withMsal } from '@azure/msal-react';
import { BlkCircularProgress } from '../helpcomponents/Progress/CircularProgress';

import Description from '../helpcomponents/Descriptions/Description';
import Swal from 'sweetalert2';
import { BlkMaterialTable } from '../helpcomponents/Tables/MaterialTable';

const useStyles = (theme) => ({
    uploadButton: {
        backgroundColor: '#0d1523',
        color: 'white',
        marginTop: '2%',
        marginBottom: '3%',
        '&.Mui-disabled': {
            opacity: 0.5,
            cursor: 'not-allowed !important',
            pointerEvents: 'fill',
            color: 'white',
            '&:hover': {
                backgroundColor: '#0d1523',
                color: 'white',
            },
        },
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
    fileContentDiv: {
        maxWidth: '80%',
        paddingTop: '1%',
        margin: 'auto',
    },
    responseDiv: {
        borderColor: 'gray',
        backgroundColor: 'whitesmoke',
        border: '3px solid #131e31',
        boxSizing: 'border-box',
        padding: '20px',
        marginTop: '10px',
        maxWidth: '450px',
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 450,
        backgroundColor: '#E0E0E0',
        color: 'black',
        borderRaduius: '25px',
    },
    copyBtn: {
        color: 'blue',
        height: '1vw',
        '&:focus': {
            outline: 'none !important',
        },
    },
});

class ImportByExcelComponent extends Component {
    static displayName = ImportByExcelComponent.name.toUpperCase();
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            loading: false,
            faulty: false,
            btnDisabled: false,
            excelFile: [],
            drawerOpen: true,
            items: [],
            fileName: '',
            responseData: {},
            fileUploaded: false,
            filePosted: false,
            descriptionAndHelpHandler: { name: 'ImportUsersByExcel' },
        };
        this.api = new APIFetcher(props.msalContext.instance);
    }

    initReactGA = () => {
        ReactGA.initialize('G-X9SBSGYK3H');
        ReactGA.pageview('/import/importbyexcel');
    };

    componentDidMount() {
        this.initReactGA();
    }

    shiftSideNav = (changeStatus) => {
        this.setState({ drawerOpen: changeStatus });
    };

    isNullOrEmpty = (d) => {
        d.forEach((e) => {
            if (
                !e.FirstName ||
                !e.LastName ||
                !e.EmailAddress ||
                !e.Application ||
                !e.Role ||
                e.FirstName === '' ||
                e.LastName === '' ||
                e.EmailAddress === '' ||
                e.Application === '' ||
                e.Role === '' /* || e.AccountIsActive === "" */
            ) {
                //AccountIsActive can not be checked?!
                this.setState({ faulty: true });
            }
        });
    };

    previewFile = (file) => {
        this.setState({ loading: true });
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                return import('xlsx').then((XLSX) => {
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    const wsname = wb.SheetNames[0];

                    const ws = wb.Sheets[wsname];

                    return resolve(XLSX.utils.sheet_to_json(ws));
                });
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
        promise.then((d) => {
            this.isNullOrEmpty(d); //Sets faulty: true (if users file is missing required props/fields)

            if (!this.state.faulty) {
                const columns = [
                    { title: 'Firstname', field: 'FirstName' },
                    { title: 'Lastname', field: 'LastName' },
                    { title: 'Email', field: 'EmailAddress' },
                    { title: 'Application', field: 'Application' },
                    { title: 'Role', field: 'Role' },
                    { title: 'Active', field: 'AccountIsActive' },
                    { title: 'Reset Password', field: 'ResetPassword' },
                ];
                const data = {
                    columns: columns,
                    data: d,
                    title: this.state.fileName,
                };
                this.setState({
                    tableData: data,
                    loading: false,
                    fileUploaded: true,
                });
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Wrong properties in excelfile. Please edit and try again',
                    footer: '<p>Check the instructions for more information</p>',
                });
            }
        });
    };

    uploadButton = () => {
        const { classes } = this.props;
        return (
            <Button
                className={classes.uploadButton}
                variant="contained"
                component="label"
            >
                Upload File
                <input
                    type="file"
                    onChange={(e) => {
                        const file = e.target.files[0];
                        if (!file) {
                        } else {
                            const checkExcel = file.name.endsWith('.xlsx');
                            if (checkExcel) {
                                this.setState({
                                    fileName: file.name,
                                    excelFile: file,
                                    fileUploaded: false,
                                    filePosted: false,
                                    faulty: false,
                                });
                                this.previewFile(file);
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Only XLSX files allowed!',
                                    footer: '<p>Check the instructions for more information</p>',
                                });
                            }
                        }
                    }}
                    hidden
                />
            </Button>
        );
    };

    async postExcelFile(file) {
        this.setState({ btnDisabled: true });
        const data = new FormData();
        data.append(file.name, file);
        const response = await await this.api.postFile(data);
        this.setState({ btnDisabled: false });
        if (response != '500' && response != null && response != '504') {
            this.setState({
                responseData: response,
                filePosted: true,
                fileUploaded: false,
            });
        } else if (response == '504') {
            alert(
                'The request timed out. Navigate to user import status to check status of the transaction.',
            );
        } else {
            alert('Something went wrong.. Please contact admin');
        }
    }

    displayResponse = () => {
        const { classes } = this.props;
        return (
            <div className={classes.responseDiv}>
                <h5>Added to queue: </h5>
                <p> {this.state.responseData.AddedToQueue} </p>
                <br />
                <h5> Company: </h5>
                <p> {this.state.responseData.Transactionlogg.CompanyId} </p>
                <br />
                <br />
                <h5> TransactionId: </h5>
                <p className="transaction">
                    {' '}
                    {
                        this.state.responseData.Transactionlogg.WA_TransactionId
                    }{' '}
                </p>
                <Button
                    className={classes.copyBtn}
                    onClick={() => {
                        navigator.clipboard.writeText(
                            this.state.responseData.Transactionlogg
                                .WA_TransactionId,
                        );
                    }}
                    color="inherit"
                >
                    Copy
                </Button>
                <br />
            </div>
        );
    };

    displayTable = () => {
        const PostButton = this.postButton;
        if (this.state.fileUploaded && !this.state.filePosted) {
            return (
                <div id="fileUploaded">
                    <BlkMaterialTable {...this.state.tableData} />
                    <PostButton />
                </div>
            );
        } else {
            return <div></div>;
        }
    };

    postButton = () => {
        const { classes } = this.props;
        return (
            <Button
                disabled={this.state.btnDisabled}
                className={classes.uploadButton}
                onClick={() => {
                    this.postExcelFile(this.state.excelFile);
                }}
            >
                Post File
            </Button>
        );
    };

    render() {
        const UploadButton = this.uploadButton;
        const Response = this.displayResponse;
        const DisplayTable = this.displayTable;
        const contentStyle = {
            textAlign: 'center',
            transition: 'margin-left 1000ms cubic-bezier(0.23, 1, 0.32, 1)',
        };
        const responseStyle = { justifyContent: 'center', marginTop: '3%' };
        const storedGroups = JSON.parse(localStorage.getItem('groups'));
        if (storedGroups[2].isMember) {
            // [0] == Admin, [1] == Export, [2] == Import, [3] == Laundry //
            if (this.state.drawerOpen) {
                contentStyle.marginLeft = 256;
            }
            return (
                <div id="returnParent" style={{ zIndex: '97' }}>
                    <SideNav
                        items={menuItems.Sales}
                        callback={this.shiftSideNav}
                    />
                    <div id="content" style={contentStyle}>
                        <br />
                        <br />
                        <div id="instructionDiv" style={responseStyle}>
                            <Description
                                parentCallBack={
                                    this.state.descriptionAndHelpHandler
                                }
                            />
                            <br />
                        </div>
                        <UploadButton />
                        <div
                            id="progressDiv"
                            style={{ marginTop: '3%', marginBottom: '3%' }}
                            hidden={!this.state.loading}
                        >
                            <BlkCircularProgress />
                        </div>
                        <DisplayTable />
                        <div
                            id="progressDiv"
                            style={{ marginTop: '3%', marginBottom: '3%' }}
                            hidden={!this.state.btnDisabled}
                        >
                            <BlkCircularProgress />
                        </div>
                        <div
                            id="response"
                            hidden={
                                !this.state.fileUploaded &&
                                !this.state.filePosted
                            }
                        >
                            {this.state.filePosted ? <Response /> : <div />}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h1>
                        YOU DO NOT HAVE ACCESS TO VIEW THE IMPORT PAGE. PLEASE
                        CONTACT YOUR ADMINISTRATOR TO GAIN ACCESS
                    </h1>
                </div>
            );
        }
    }
}

export default withMsal(withStyles(useStyles)(ImportByExcelComponent));
