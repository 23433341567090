import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TextField, FormControl, Button } from '@mui/material';
import { BoolDropdown } from '../Dropdowns/BoolDropdown';
import { useMsal } from '@azure/msal-react';

const useStyles = makeStyles((theme) => ({
    table: {
        marginRight: '20px',
        marginTop: '20px',
        marginLeft: '20px',
    },
    inputFormStyle: {
        minWidth: '40vw', //change
        maxWidth: '50%',
        borderRadius: 15,
        padding: '30px',
        backgroundColor: '#E0E0E0',
        boxSizing: 'border-box',
        color: 'black',
        ['@media screen and (max-width: 900px)']: {
            maxWidth: '100% !important',
        },
    },
    descriptionText: {
        backgroundColor: '#E0E0E0',
    },
    formDiv: {
        display: 'flex',
        flexDirection: 'column', //column for xs screen size
        alignItems: 'center',
        minWidth: '60%', //change
    },
    formControl: {
        margin: theme.spacing(1),
        color: 'black',
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#E0E0E0',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            //border to TextField in form
            borderColor: 'black',
        },
    },
    textField: {
        backgroundColor: 'white !important',
        '&$focused $notchedOutline': {
            borderColor: 'black',
            color: 'black',
        },
    },
    focused: {
        color: 'black',
    },
    inputBorder: {
        color: 'grey !important', //fontcolor to placeholder in form
        fontSize: 14,
        borderRadius: 25,
    },
    submitButton: {
        color: 'white',
        marginTop: '5%',
        backgroundColor: '#0d1523',
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
}));

export const BLKFormComponent = (data) => {
    const { instance, accounts, inProgress } = useMsal();
    const classes = useStyles();
    const [dropdownValue, setDropdownValue] = useState('');

    const passBackToParent = (formData) => {
        formData.preventDefault();

        if (data.parameters === null || data.parameters === undefined) {
            data.parentCallBack(null);
        } else {
            const obj = {};
            //Object.getOwnPropertyNames gets the names of props from the form
            //.filter we filter the whole form div and only keep the objects where the name match data.parameters
            //.map and .includes searchs for the nameprop from data.parameteras to match against .filter
            //.foreach for every element we take the name of the element and match it with its value, create objpropName by obj[propname] and then assign value.
            Object.getOwnPropertyNames(formData.target.elements)
                .filter((name) =>
                    data.parameters.map((name) => name.name).includes(name),
                )
                .forEach((element) => {
                    if (formData.target[element].value !== '') {
                        obj[element] = formData.target[element].value;
                    }
                });

            if (Object.keys(dropdownValue).length !== 0) {
                if (dropdownValue !== '') {
                    obj['changedby'] = dropdownValue;
                }
            }
            data.parentCallBack(obj);
        }
    };

    if (data.parameters !== undefined) {
        return (
            <div className={classes.formDiv}>
                <form
                    className={classes.inputFormStyle}
                    onSubmit={passBackToParent}
                    autoComplete="off"
                >
                    {data.parameters === null ? (
                        <div />
                    ) : (
                        data.parameters
                            .filter((type) => type.controlType !== null)
                            .map((field) =>
                                field.controlType === 'checkbox' ? (
                                    <FormControl
                                        key={field.name}
                                        className={classes.formControl}
                                        required
                                        fullWidth
                                    >
                                        <p
                                            className={classes.descriptionText}
                                            hidden={field.hidden}
                                        >
                                            {field.description.toUpperCase()}
                                        </p>
                                        <BoolDropdown {...field} />
                                    </FormControl>
                                ) : field.controlType !== 'select' ? (
                                    <FormControl
                                        key={field.name}
                                        className={classes.formControl}
                                        required
                                        fullWidth
                                    >
                                        <p
                                            className={classes.descriptionText}
                                            hidden={field.hidden}
                                        >
                                            {field.description.toUpperCase()}
                                        </p>
                                        <TextField
                                            hidden={field.hidden}
                                            type={field.controlType}
                                            required={field.required}
                                            key={field.name}
                                            label={field.name}
                                            name={field.name}
                                            id={field.name}
                                            variant="filled"
                                            InputProps={{
                                                classes: {
                                                    root: classes.textField,
                                                    focused: classes.focused,
                                                },
                                            }}
                                            InputLabelProps={{
                                                className: classes.inputBorder,
                                            }}
                                        />
                                    </FormControl>
                                ) : field.controlParameters !== null ? (
                                    <FormControl
                                        key={field.name}
                                        className={classes.formControl}
                                        required
                                        fullWidth
                                    >
                                        <p
                                            className={classes.descriptionText}
                                            hidden={field.hidden}
                                        >
                                            {field.description.toUpperCase()}
                                        </p>
                                        <TextField
                                            value={dropdownValue}
                                            onChange={(e) =>
                                                setDropdownValue(e.target.value)
                                            }
                                            hidden={field.hidden}
                                            required={field.required}
                                            key={field.name}
                                            label={field.name}
                                            name={field.name}
                                            variant="filled"
                                            InputProps={{
                                                classes: {
                                                    root: classes.textField,
                                                    focused: classes.focused,
                                                },
                                            }}
                                            InputLabelProps={{
                                                className: classes.inputBorder,
                                            }}
                                        />
                                    </FormControl>
                                ) : (
                                    <FormControl
                                        key={field.name}
                                        className={classes.formControl}
                                        required
                                        fullWidth
                                    >
                                        <p
                                            className={classes.descriptionText}
                                            hidden={field.hidden}
                                        >
                                            {field.description.toUpperCase()}
                                        </p>
                                        <BoolDropdown {...field} />
                                    </FormControl>
                                ),
                            )
                    )}
                    <Button className={classes.submitButton} type="submit">
                        GET LIST
                    </Button>
                </form>
            </div>
        );
    } else {
        return (
            <div className={classes.formDiv}>
                <form
                    className={classes.inputFormStyle}
                    onSubmit={passBackToParent}
                    autoComplete="off"
                >
                    <Button className={classes.submitButton} type="submit">
                        GET LIST
                    </Button>
                </form>
            </div>
        );
    }
};
