import React, { Component } from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import menuItems from '../Items.json';
import SideNav from '../navigation/SideNav';
import Button from '@mui/material/Button';
import { BlkMaterialTable } from '../helpcomponents/Tables/MaterialTable';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import GetAppIcon from '@mui/icons-material/GetApp';
import Container from '@mui/material/Container';
import { BlkCircularProgress } from '../helpcomponents/Progress/CircularProgress';
import { MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import APIFetcher from '../remoteData/APIFetcher';
import Swal from 'sweetalert2';
import Description from '../helpcomponents/Descriptions/Description';
import { withMsal } from '@azure/msal-react';

const CustomDesc = styled(Description)`
    max-width: 100% !important;
    min-width: 100% !important;
`;

const useStyles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    inputFormStyle: {
        minWidth: '40vw',
        width: '100%',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '30px',
        paddingRight: '30px',
        backgroundColor: '#E0E0E0',
        boxSizing: 'border-box',
        color: 'black', //This gives header yellow color.
    },
    formDiv: {
        display: 'flex',
        flexDirection: 'row', //column for xs screen size
        alignItems: 'center',
    },
    input: {
        color: 'black', //fontcolor for labels and selects
        fontSize: 14,
        borderRadius: '10px',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
        '&:active': {
            borderRadius: '10px',
        },
        '&:focus': {
            borderRadius: '10px',
        },
    },
    inputBorder: {
        color: 'grey !important', //fontcolor to placeholder in form
        fontSize: 14,
        borderRadius: 25,
    },
    gridForm: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        ['@media screen and (max-width: 864px)']: {
            display: 'block',
        },
    },
    formselect: {
        maxMenuHeight: 250,
        height: '55px', //Same height for all formcontrols. (SELECT & TextField)
        color: 'black',
        border: '2px solid grey',
        borderRadius: '5px',
    },
    icon: {
        color: 'black',
    },
    fileContentDiv: {
        paddingTop: '1vh',
    },
    formControl: {
        margin: theme.spacing(1),
        color: 'black',
        borderRadius: '10px',
        backgroundColor: 'white',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            //border to TextField in form
            borderColor: 'black',
        },
    },
    submitButton: {
        backgroundColor: '#0d1523',
        color: 'white',
        marginLeft: '1%',
        maxWidth: '80%',
        width: '60%',
        height: '50px',
        fontSize: 14,
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 450,
        backgroundColor: '#E0E0E0',
        color: 'black',
        borderRaduius: '25px',
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    buttonStyle: {
        backgroundColor: '#0d1523',
        marginTop: '3%',
        color: 'white',
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
    btn: {
        backgroundColor: '#0d1523',
        color: 'white',
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
    disabledInputHoverColor: {
        '&:hover': {
            outline: 'none !important',
        },
    },
    container: {
        padding: '0 !important',
    },
});

class ListImportStatusComponent extends Component {
    static displayName = ListImportStatusComponent.name.toUpperCase();
    constructor(props) {
        super(props);
        this.state = {
            idsOnDate: null,
            btnDisabled: false,
            dataPosted: false,
            loading: false,
            showLookup: false,
            showImportStatus: false,
            drawerOpen: true,
            responseData: {},
            Created: '',
            CompanyId: '',
            StatusOption: '',
            descriptionAndHelpHandler: {
                name: 'ListImportStatus',
                func: this.setGetHelp,
                helpDiv: true,
            },
            filterOption: 'all',
        };
        this.api = new APIFetcher(props.msalContext.instance);
    }

    initReactGA = () => {
        ReactGA.initialize('G-X9SBSGYK3H');
        ReactGA.pageview('/laundry/listimportstatus');
    };

    componentDidMount() {
        this.initReactGA();
    }

    shiftSideNav = (changeStatus) => {
        this.setState({ drawerOpen: changeStatus });
    };
    onDropDownChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, CompanyId: '' });
    };
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    setTableData = (e) => {
        this.setState({ filterOption: e });
    };

    getTikosIds = (e) => {
        this.setState({ Created: e, idsOnDate: null });
        const obj = { URI: 'LookUpTransactionLogg', Date: e };
        this.setDropdownWithIds(obj);
    };

    async setDropdownWithIds(obj) {
        const result = await this.api.listImportStatus(obj);
        this.setState({ idsOnDate: result.Payload });
    }

    clearFields = () => {
        this.setState({
            StatusOption: '',
            CompanyId: '',
            Created: '',
            idsOnDate: null,
            dataPosted: false,
        });
    };

    async getTransactions(obj) {
        const result = await this.api.listImportStatus(obj);
        this.setState({ loading: false });
        if (result != '500' && result != 500 && result != null) {
            if (result.Payload.length < 1) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    showCloseButton: true,
                    text: 'No results found..',
                });
            } else {
                this.setState({ dataPosted: true, responseData: result });
                if (this.state.StatusOption == 'LookUpTransactionLogg') {
                    this.setState({ showLookup: true });
                } else {
                    this.setState({ showImportStatus: true });
                }
            }
        } else {
            alert('Something went wrong..');
        }
    }

    getData = (e) => {
        e.preventDefault();
        this.setState({
            dataPosted: false,
            showLookup: false,
            loading: true,
        }); /* btnDisabled: !this.state.btnDisabled */
        const data = new FormData(e.target);
        const obj = {
            CompanyId: data.get('CompanyId'),
            URI: data.get('StatusOption'),
            Date: data.get('Created'),
        };

        if (obj.CompanyId === 'Print out all') {
            obj.CompanyId = '';
        }
        this.getTransactions(obj);
    };

    checkNewTransactions = () => {
        const { classes } = this.props;

        return (
            <Button className={classes.btn} onClick={this.clearFields}>
                New Search
            </Button>
        );
    };

    inputForm = () => {
        const { classes } = this.props;

        const statusOptions = [
            {
                id: 1,
                value: 'LookUpTransactionLogg',
                label: 'Import activity by date',
            },
            {
                id: 2,
                value: 'CustomerImportStatus',
                label: 'List import status',
            },
            {
                id: 3,
                value: 'CustomerGroupedImportStatus',
                label: 'List grouped import status',
            },
        ];
        if (this.state.idsOnDate !== null) {
            if (this.state.idsOnDate.length === 0) {
                this.setState({ idsOnDate: null });
            }
        }
        return (
            <Container
                className={classes.container}
                component="main"
                maxWidth={false}
            >
                <CssBaseline />
                <div className={classes.formDiv}>
                    <form
                        className={classes.inputFormStyle}
                        autoComplete="off"
                        onSubmit={this.getData}
                    >
                        <Grid className={classes.gridForm}>
                            <FormControl
                                className={classes.formControl}
                                required
                                fullWidth
                            >
                                <TextField
                                    select
                                    label="StatusOption"
                                    defaultValue={null}
                                    value={this.state.StatusOption}
                                    name="StatusOption"
                                    onChange={this.onDropDownChange}
                                    SelectProps={{
                                        classes: { icon: classes.icon },
                                        native: false,
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputBorder,
                                    }}
                                    variant="filled"
                                    required
                                >
                                    {statusOptions.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <br />
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    required={
                                        this.state.StatusOption ==
                                        'CustomerGroupedImportStatus'
                                    }
                                    disabled={this.state.StatusOption == ''}
                                    InputProps={{
                                        className: classes.input,
                                        disableUnderline: true,
                                        classes: {
                                            disabled:
                                                classes.disabledInputHoverColor,
                                        },
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputBorder,
                                    }}
                                    variant="filled"
                                    type="date"
                                    name="Created"
                                    value={this.state.Created}
                                    onChange={(e) =>
                                        this.getTikosIds(e.target.value)
                                    }
                                    label="Created"
                                />
                            </FormControl>
                            <br />
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    select
                                    required={
                                        this.state.StatusOption ==
                                        'CustomerGroupedImportStatus'
                                    }
                                    disabled={
                                        this.state.StatusOption ===
                                            'LookUpTransactionLogg' ||
                                        this.state.Created === ''
                                    }
                                    SelectProps={{
                                        classes: { icon: classes.icon },
                                        native: false,
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                        disableUnderline: true,
                                        classes: {
                                            disabled:
                                                classes.disabledInputHoverColor,
                                        },
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputBorder,
                                    }}
                                    variant="filled"
                                    type="text"
                                    name="CompanyId"
                                    value={this.state.CompanyId}
                                    onChange={this.onChange}
                                    label="TikosId"
                                >
                                    {this.state.idsOnDate === null ? (
                                        <MenuItem value={'all'}>
                                            <em>No result</em>
                                        </MenuItem>
                                    ) : (
                                        this.state.idsOnDate.map((option) => (
                                            <MenuItem
                                                key={option.Id}
                                                value={option.Id}
                                            >
                                                {option.Id} -{' '}
                                                {option.Description}
                                            </MenuItem>
                                        ))
                                    )}
                                    {this.state.StatusOption ===
                                        'CustomerImportStatus' &&
                                    this.state.idsOnDate !== null ? (
                                        <MenuItem
                                            key={-1}
                                            value="Print out all"
                                        >
                                            Print out all
                                        </MenuItem>
                                    ) : (
                                        <div />
                                    )}
                                </TextField>
                            </FormControl>
                            <br />
                            <br />
                            <br />
                            <Button
                                disabled={this.state.btnDisabled}
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submitButton}
                                endIcon={<GetAppIcon />}
                                color="primary"
                            >
                                Check Status
                            </Button>
                            <br />
                            <br />
                            <Button
                                disabled={this.state.btnDisabled}
                                onClick={this.clearFields}
                                fullWidth
                                variant="contained"
                                className={classes.submitButton}
                                color="primary"
                            >
                                Clear fields
                            </Button>
                        </Grid>
                    </form>
                </div>
            </Container>
        );
    };

    displayResponse = () => {
        const lookupColumns = [
            { title: 'Id', field: 'Id' },
            { title: 'Description', field: 'Description' },
            { title: 'Date', field: 'Filter' },
        ];
        const customerGroupedColumns = [
            { title: 'CustomerNumber', field: 'CustomerId' },
            { title: 'Users', field: 'Users' },
            { title: 'Status', field: 'Status' },
            { title: 'Inserted', field: 'Inserted' },
            { title: 'Processed', field: 'Processed' },
        ];
        const customerColumns = [
            { title: 'Id', field: 'CustomerId', filterPlaceholder: 'Id' },
            { title: 'Email', field: 'Email', filtering: false },
            { title: 'Status', field: 'Status', filterPlaceholder: 'Status' },
            { title: 'EmpId', field: 'EmployeeId', filtering: false },
            { title: 'Fname', field: 'FirstName', filtering: false },
            { title: 'LName', field: 'LastName', filtering: false },
            {
                title: 'Department',
                field: 'Department',
                filterPlaceholder: 'Department',
            },
            {
                title: 'Costcenter',
                field: 'CostCenter',
                filterPlaceholder: 'Costcenter',
            },
            {
                title: 'Locker1',
                field: 'Locker1',
                filterPlaceholder: 'Locker1',
            },
            {
                title: 'Locker1Position',
                field: 'Locker1Position',
                filterPlaceholder: 'Locker1Position',
            },
            { title: 'EndDate', field: 'DateOfSeparation', filtering: false },
            { title: 'Inserted', field: 'Inserted', filtering: false },
            { title: 'Processed', field: 'Processed', filtering: false },
        ];

        if (this.state.StatusOption == 'LookUpTransactionLogg') {
            const data = {
                columns: lookupColumns,
                data: this.state.responseData.Payload,
                title: 'Transactions',
            };
            return <BlkMaterialTable {...data} />;
        } else if (this.state.StatusOption == 'CustomerGroupedImportStatus') {
            const data = {
                columns: customerGroupedColumns,
                data: this.state.responseData.Payload,
                title: 'Transactions',
            };
            return <BlkMaterialTable {...data} />;
        } else if (this.state.StatusOption == 'CustomerImportStatus') {
            const data = {
                columns: customerColumns,
                data: this.state.responseData.Payload,
                title: 'Transactions',
                filter: true,
            };
            return <BlkMaterialTable {...data} />;
        } else return 'no statusoption provided..';
    };

    customDesc = () => {
        return (
            <CustomDesc parentCallBack={this.state.descriptionAndHelpHandler} />
        );
    };

    render() {
        const mainContentStyle = {
            textAlign: 'center',
            margin: '0 auto',
            transition: 'margin-left 1000ms cubic-bezier(0.23, 1, 0.32, 1)',
        };
        const btnStyle = { textAlign: 'center', padding: '24px' };
        const storedGroups = JSON.parse(localStorage.getItem('groups'));

        const InputForm = this.inputForm;
        const ImportStatus = this.displayResponse;
        const NewTransactionsBtn = this.checkNewTransactions;
        const Desc = this.customDesc;

        if (storedGroups[3].isMember) {
            //[0] == Admin, [1] == Export, [2] == Import, [3] == Laundry
            if (this.state.drawerOpen) {
                mainContentStyle.marginLeft = 256;
            }
            return (
                <div id="parentDiv" style={{ zIndex: '97' }}>
                    <SideNav
                        items={menuItems.Laundry}
                        callback={this.shiftSideNav}
                    />
                    <div id="mainContent" style={mainContentStyle}>
                        <br />
                        <br />
                        <div
                            id="descInput"
                            style={{
                                marginTop: '3%',
                                marginRight: '1vw',
                                backgroundColor: '#E0E0E0',
                                borderRadius: '15px',
                                padding: '1vw',
                            }}
                        >
                            <div
                                id="description"
                                style={{
                                    paddingLeft: '24.5px',
                                    paddingRight: '70%',
                                    backgroundColor: '#E0E0E0',
                                }}
                            >
                                <Desc />
                            </div>
                            <div id="inputs">
                                <InputForm style={{ marginTop: '3%' }} />
                            </div>
                        </div>
                        <div
                            id="responseDiv"
                            style={{ paddingRight: '1vw' }}
                            hidden={!this.state.dataPosted}
                        >
                            <ImportStatus />
                        </div>

                        <div
                            id="progressDiv"
                            style={{ marginTop: '3%', marginBottom: '3%' }}
                            hidden={!this.state.loading}
                        >
                            <BlkCircularProgress />
                        </div>
                        <div
                            id="btn"
                            style={btnStyle}
                            hidden={!this.state.dataPosted}
                        >
                            <NewTransactionsBtn />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h1>
                        YOU DO NOT HAVE ACCESS TO VIEW THE LAUNDRY PAGE. PLEASE
                        CONTACT YOUR ADMINISTRATOR TO GAIN ACCESS
                    </h1>
                </div>
            );
        }
    }
}

export default withMsal(withStyles(useStyles)(ListImportStatusComponent));
