import { AppBar, Toolbar, Button, IconButton, Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HomeImage from '../../assets/logo.png';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import APIFetcher from '../remoteData/APIFetcher';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { useMsal } from '@azure/msal-react';

const useStyles = makeStyles(() => ({
    header: {
        zIndex: 999,
        backgroundColor: '#0d1523',
        alignItems: 'center',
        color: 'white',
        '@media (max-width: 900px)': {
            paddingLeft: 0,
        },
    },
    toolbar: {
        justifyContent: 'center',
        width: '100%',
        '@media (min-width: 1240px)': {
            paddingLeft: '200px',
        },
        '@media (min-width: 1500px)': {
            paddingLeft: '24px',
        },
    },
    drawerContainer: {
        //mobileview
        display: 'grid',
        padding: '20px 30px',
    },
    logo: {
        width: 200,
        height: 'auto',
    },
    childButton: {
        paddingLeft: '15px',
        paddingRight: '15px',
        marginLeft: '38px',
        color: 'white',
        border: '1px solid #2c3b56',
        backgroundColor: '#131e31',
        '&.Mui-disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
            pointerEvents: 'fill',
            color: 'white !important',
            backgroundColor: '#0d1523 !important',
            '&:hover': {
                opacity: 0.5,
            },
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: '#0d1523',
            color: 'white',
        },
        '&:hover': {
            backgroundColor: '#0d1523',
            opacity: 0.8,
            color: 'white',
        },
        '&.active': {
            backgroundColor: '#ffe002',
            color: 'black',
        },
    },
    mobileButton: {
        color: 'white',
        borderColor: '#2c3b56',
        float: 'center',
        minWidth: '100%',
        marginTop: '7px',
        backgroundColor: '#131e31',
        '&.Mui-disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
            color: 'white',
            pointerEvents: 'fill',
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: '#0d1523',
            color: 'white',
        },
        '&:hover': {
            backgroundColor: '#0d1523',
            opacity: 0.8,
            color: 'white',
        },
        '&.active': {
            backgroundColor: '#ffe002',
            color: 'black',
        },
    },
}));

export default function Header() {
    const { instance, accounts, inProgress } = useMsal();
    const {
        header,
        logo,
        childButton,
        toolbar,
        drawerContainer,
        mobileButton,
    } = useStyles();
    const [image, setImage] = useState('');
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });
    const [headerData, setHeaderData] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { mobileView, drawerOpen } = state;
    const [userGroups, setUserGroups] = useState(null);
    const api = new APIFetcher(instance);

    useEffect(() => {
        async function onLoad() {
            await Promise.all([
                api.getLoggedInUsersGroups().then((response) => {
                    setUserGroups(response);
                    handleHeaderOptions(response);
                }),
                api
                    .getProfilePictures() //CACHE
                    .then((response) => {
                        if (response === 500) {
                            setImage('');
                        } else setImage(URL.createObjectURL(response));
                    }),
            ]);
        }

        onLoad();

        const setResponsiveness = () => {
            return window.innerWidth < 1240
                ? setState((prevstate) => ({ ...prevstate, mobileView: true }))
                : setState((prevState) => ({
                      ...prevState,
                      mobileView: false,
                  }));
        };

        setResponsiveness();

        window.addEventListener('resize', () => setResponsiveness());

        return () => {
            window.removeEventListener('resize', () => setResponsiveness());
        };
    }, []);

    const handleHeaderOptions = (groups) => {
        const adminHeader = [
            {
                label: 'Laundry',
                href: '/laundry',
                userGroup: 'SalesSupportPortal-Laundry',
            },
            {
                label: 'Export',
                href: '/export',
                userGroup: 'SalesSupportPortal-Export',
            },
            {
                label: 'Import',
                href: '/import',
                userGroup: 'SalesSupportPortal-Import',
            },
            {
                label: 'Admin',
                href: '/admin',
                userGroup: 'SalesSupportPortal-Admin',
            },
            {
                label: 'Promotions',
                href: '/promotions',
                userGroup: 'SalesSupportPortal-Promotions',
            },
            {
                label: 'Notifications',
                href: '/notifications',
                userGroup: 'SalesSupportPortal-Notifications',
            },
            {
                label: 'Hangtight',
                href: '/hangtight',
                userGroup: 'SalesSupportPortal-Wellhung',
            },
            {
                label: 'AI-Search',
                href: '/aisearch',
                userGroup: null,
            },
        ];

        const nonAdminHeader = [
            {
                label: 'Laundry',
                href: '/laundry',
                userGroup: 'SalesSupportPortal-Laundry',
            },
            {
                label: 'Export',
                href: '/export',
                userGroup: 'SalesSupportPortal-Export',
            },
            {
                label: 'Import',
                href: '/import',
                userGroup: 'SalesSupportPortal-Import',
            },
            {
                label: 'Promotions',
                href: '/promotions',
                userGroup: 'SalesSupportPortal-Promotions',
            },
            {
                label: 'Notifications',
                href: '/notifications',
                userGroup: 'SalesSupportPortal-Notifications',
            },
            {
                label: 'Hangtight',
                href: '/hangtight',
                userGroup: 'SalesSupportPortal-Wellhung',
            },
        ];

        localStorage.setItem('groups', JSON.stringify(groups));

        const storedGroups = JSON.parse(localStorage.getItem('groups'));

        if (storedGroups[0]?.isMember /* && this.props.location.state */) {
            // [0] == Admin, [1] == Export, [2] == Import, [3] == Laundry //
            setHeaderData(adminHeader);
        } else {
            setHeaderData(nonAdminHeader);
        }
    };

    function userIsMemberOf(userGroup) {
        if (userGroups === null) {
            return false;
        } else {
            const group = userGroups.find((x) => x.name === userGroup);
            return group?.isMember;
        }
    }

    const handleAvatClick = (event) => {
        //setAnchorEl(event.currentTarget);
    };

    const handleAvatClose = () => {
        //setAnchorEl(null);
    };

    const DisplayDesktop = () => {
        return (
            <Toolbar className={toolbar}>
                {homeLogo}
                {image === '' ? (
                    <div />
                ) : (
                    <Avatar
                        src={image}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        style={{
                            position: 'fixed',
                            right: '64px',
                            color: '#fafafa',
                            backgroundColor: 'purple',
                            cursor: 'pointer',
                        }}
                        id="avatartest"
                        onClick={handleAvatClick}
                    >
                        JS
                    </Avatar>
                )}
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleAvatClose}
                >
                    <MenuItem onClick={handleAvatClose}>Profile</MenuItem>
                    <MenuItem onClick={handleAvatClose}>My account</MenuItem>
                    <MenuItem onClick={handleAvatClose}>Logout</MenuItem>
                </Menu>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {getMenuButtons()}
                </div>
            </Toolbar>
        );
    };

    const getMenuButtons = () => {
        return headerData.map(({ label, href, userGroup }) => {
            const disabled = userGroup ? !userIsMemberOf(userGroup) : false;
            return (
                <Button
                    key={label}
                    disableRipple
                    id={label}
                    disabled={disabled}
                    className={childButton}
                    component={NavLink}
                    to={disabled ? '#' : href}
                >
                    {label}
                </Button>
            );
        });
    };

    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: true }));

        const handleDrawerClose = () =>
            setState((prevstate) => ({ ...prevstate, drawerOpen: false }));

        return (
            <Toolbar>
                <IconButton
                    style={{ position: 'fixed', right: '24px' }}
                    {...{
                        edge: 'end',
                        color: 'inherit',
                        'aria-label': 'menu',
                        'aria-haspopup': 'true',
                        onClick: handleDrawerOpen,
                    }}
                    size="large"
                >
                    <MenuIcon />
                </IconButton>

                <Drawer
                    {...{
                        anchor: 'right',
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}
                >
                    <div className={drawerContainer}>{getDrawerChoices()}</div>
                </Drawer>
                <Avatar
                    src={image}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    style={{
                        position: 'fixed',
                        right: '64px',
                        color: '#fafafa',
                        backgroundColor: 'purple',
                        cursor: 'pointer',
                    }}
                    id="avatartest"
                    onClick={handleAvatClick}
                >
                    JS
                </Avatar>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleAvatClose}
                >
                    <MenuItem onClick={handleAvatClose}>Profile</MenuItem>
                    <MenuItem onClick={handleAvatClose}>My account</MenuItem>
                    <MenuItem onClick={handleAvatClose}>Logout</MenuItem>
                </Menu>
                <div>{phoneHomeLogo}</div>
            </Toolbar>
        );
    };

    const getDrawerChoices = () => {
        const handleDrawerClose = () =>
            setState((prevstate) => ({ ...prevstate, drawerOpen: false }));
        return headerData.map(({ label, href, userGroup }) => {
            const disabled = userGroup ? !userIsMemberOf(userGroup) : false;
            return (
                <div key={label} style={{ textDecoration: 'none' }}>
                    <Button
                        to={disabled ? '#' : href}
                        component={NavLink}
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        variant="outlined"
                        disabled={disabled}
                        onClick={handleDrawerClose}
                        className={mobileButton}
                    >
                        {label}
                    </Button>
                </div>
            );
        });
    };

    const phoneHomeLogo = (
        <NavLink style={{ left: '18px' }} to="/">
            <img className={logo} src={HomeImage} alt="BLKLOGO" />
        </NavLink>
    );

    const homeLogo = (
        <NavLink style={{ position: 'fixed', left: '24px' }} to="/">
            <img className={logo} src={HomeImage} alt="BLKLOGO" />
        </NavLink>
    );

    return (
        <header>
            <AppBar className={header}>
                {mobileView ? displayMobile() : DisplayDesktop()}
            </AppBar>
        </header>
    );
}
