import React, { FC, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import './promotions.css';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import {
    FormControlLabel,
    FormGroup,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Switch,
    Typography,
} from '@mui/material';
import PromotionLimits, {
    PromotionLimitsState,
} from './promotions.limits.component';
import { useQuery } from '@tanstack/react-query';
import { ILookupResponse } from 'types/lookups.type';
import LookupService from 'services/lookups.service';
import PromotionService from 'services/promotions.service';
import {
    IPromotionCodeGroupResponse,
    IPromotionLimitResponse,
    IPromotionResponse,
} from 'types/promotions.type';
import { useMsal } from '@azure/msal-react';

interface PromotionProps {
    id?: string;
    readOnly?: boolean;
    showCodeGroup?: boolean;
    onChange(state: PromotionState);
}

export interface PromotionState extends IPromotionResponse {
    selectedCodeGroupId?: string;
}

const Promotion: FC<PromotionProps> = ({
    id,
    readOnly = false,
    showCodeGroup = false,
    onChange = () => {},
}) => {
    const { instance, accounts } = useMsal();
    const promotionService = new PromotionService(instance);
    const lookupService = new LookupService(instance);

    const [state, setState] = useState<PromotionState>({
        codeGroups: [],
        name: '',
        description: '',
        minOrderValue: 0,
        discountPercentage: 0,
        entries: [],
        cultures: [],
        isActive: false,
    } as any);
    const [selectedCodeGroupLimit, setSelectedCodeGroupLimit] =
        useState<IPromotionLimitResponse>({
            startDate: new Date(),
            endDate: new Date(),
            limitMarkets: [],
            limitPerCode: 0,
            limitPerEmail: 0,
        });
    const [selectedCodeGroupId, setSelectedCodeGroupId] = useState<string>('');
    const [selectedCodeGroup, setSelectedCodeGroup] =
        useState<IPromotionCodeGroupResponse>({
            id: '',
            counter: 0,
            allowAppendOfAutoGeneratedCodes: false,
            isActive: true,
            source: '',
            promotionId: '',
            promotion: {} as any,
            codes: [],
            limits: [],
            startDate: new Date(),
            endDate: new Date(),
            limitMarkets: [],
            limitPerCode: 0,
            limitPerEmail: 0,
        });
    const [selectedCultureCode, setSelectedCultureCode] =
        useState<string>('en-GB');

    const guessCultureCode = (response: IPromotionResponse) =>
        response.cultures.find((x) => x.name)?.cultureCode;

    useEffect(() => {
        if (id) {
            promotionService.getPromotion(id).then((result) => {
                setState(result.data);
                if (result.data.codeGroups.length == 1) {
                    setSelectedCodeGroupId(result.data.codeGroups[0].id);
                }
                const cultureCode = guessCultureCode(result.data);
                if (cultureCode) {
                    setSelectedCultureCode(cultureCode);
                }
            });
        } else {
            setSelectedCodeGroupId('');
            setSelectedCodeGroupLimit({
                startDate: new Date(),
                endDate: new Date(),
                limitMarkets: [],
                limitPerCode: 0,
                limitPerEmail: 0,
            });
            setSelectedCodeGroup({
                id: '',
                counter: 0,
                allowAppendOfAutoGeneratedCodes: false,
                isActive: true,
                source: '',
                promotionId: '',
                promotion: {} as any,
                codes: [],
                limits: [],
                startDate: new Date(),
                endDate: new Date(),
                limitMarkets: [],
                limitPerCode: 0,
                limitPerEmail: 0,
            });
            setState({
                codeGroups: [],
                name: '',
                description: '',
                minOrderValue: 0,
                discountPercentage: 0,
                entries: [],
                cultures: [],
                isActive: false,
            } as any);
        }
    }, [id]);

    useEffect(() => {
        if (selectedCodeGroupId) {
            const group = state.codeGroups.find(
                (x) => x.id == selectedCodeGroupId,
            );
            setSelectedCodeGroupLimit(
                group ?? {
                    startDate: new Date(),
                    endDate: new Date(),
                    limitMarkets: [],
                    limitPerCode: 0,
                    limitPerEmail: 0,
                },
            );
            setSelectedCodeGroup(
                group ?? {
                    id: '',
                    counter: 0,
                    allowAppendOfAutoGeneratedCodes: false,
                    isActive: true,
                    source: '',
                    promotionId: '',
                    promotion: {} as any,
                    codes: [],
                    limits: [],
                    startDate: new Date(),
                    endDate: new Date(),
                    limitMarkets: [],
                    limitPerCode: 0,
                    limitPerEmail: 0,
                },
            );
            handleStateChange({ selectedCodeGroupId: selectedCodeGroupId });
        }
    }, [selectedCodeGroupId]);

    const handleStateChange = (partialState: Partial<PromotionState>) => {
        const newState = { ...state, ...partialState };
        setState(newState);
        onChange(newState);
    };

    const handleNameChange = (cultureCode: string, name: string) => {
        let cultures = state.cultures;

        const culture = cultures.find((x) => x.cultureCode == cultureCode);

        if (culture) {
            culture.name = name;
        } else {
            cultures = (cultures ?? []).concat({
                cultureCode,
                name,
                description: '',
            });
        }

        handleStateChange({ cultures });
    };

    const handleDescriptionChange = (
        cultureCode: string,
        description: string,
    ) => {
        let cultures = state.cultures;

        const culture = cultures.find((x) => x.cultureCode == cultureCode);

        if (culture) {
            culture.description = description;
        } else {
            cultures = (cultures ?? []).concat({
                cultureCode,
                name: '',
                description,
            });
        }

        handleStateChange({ cultures });
    };

    const handleAppendAutoGeneratedCodes = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => {
        const group = {
            allowAppendOfAutoGeneratedCodes: checked,
        } as Partial<IPromotionCodeGroupResponse>;

        const codeGroups = state.codeGroups;

        if (selectedCodeGroupId) {
            const groupIndex = codeGroups.findIndex(
                (x) => x.id == selectedCodeGroupId,
            );

            if (groupIndex >= 0) {
                codeGroups[groupIndex] = {
                    ...codeGroups[groupIndex],
                    ...group,
                };
                setSelectedCodeGroup(codeGroups[groupIndex]);
            }
        } else if (codeGroups.length == 1) {
            codeGroups[0] = { ...codeGroups[0], ...group };
            setSelectedCodeGroup(codeGroups[0]);
        } else {
            codeGroups.push({
                ...group,
                isActive: true,
                counter: 0,
                id: '',
            } as any);
            setSelectedCodeGroup(codeGroups[0]);
        }

        handleStateChange({ codeGroups: codeGroups });
    };

    const handleSetCodeGroupLimit = (event: PromotionLimitsState) => {
        const limit = {
            limitMarkets: event.selectedMarkets,
            limitPerEmail: event.limitOncePerEmail ? 1 : 0,
            limitPerCode: event.singleUsage ? 1 : 0,
            startDate: event.startDate.toDate(),
            endDate: event.endDate.toDate(),
        } as IPromotionLimitResponse;

        setSelectedCodeGroupLimit(limit);

        const codeGroups = state.codeGroups;

        if (selectedCodeGroupId) {
            const groupIndex = codeGroups.findIndex(
                (x) => x.id == selectedCodeGroupId,
            );

            if (groupIndex >= 0) {
                codeGroups[groupIndex] = {
                    ...codeGroups[groupIndex],
                    ...limit,
                };
            }
        } else if (codeGroups.length == 1) {
            codeGroups[0] = { ...codeGroups[0], ...limit };
        } else {
            codeGroups.push({
                ...limit,
                isActive: true,
                allowAppendOfAutoGeneratedCodes: true,
                counter: 0,
                id: '',
            } as any);
        }

        handleStateChange({ codeGroups: codeGroups });
    };

    const { data: languages } = useQuery<ILookupResponse[]>(
        ['languages'],
        async () => {
            const { data } = await lookupService.listLanguages();
            return data;
        },
        {
            keepPreviousData: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        },
    );

    return (
        <Container sx={{ padding: 0 }}>
            <Stack spacing={1} sx={{ paddingTop: 2 }}>
                <FormControl sx={{ width: '22ch' }}>
                    <TextField
                        value={state.discountPercentage}
                        label="Discount %"
                        placeholder="% of order total"
                        variant="outlined"
                        required
                        error={
                            state.discountPercentage > 100 ||
                            state.discountPercentage < 0
                        }
                        onChange={(event) =>
                            handleStateChange({
                                discountPercentage: Number(event.target.value),
                            })
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    %
                                </InputAdornment>
                            ),
                            readOnly: readOnly,
                        }}
                        inputProps={{
                            style: { textAlign: 'right' },
                            'aria-label': 'discount percentage',
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            maxLength: 3,
                            min: 1,
                            max: 100,
                        }}
                    />
                </FormControl>

                <Box>
                    <Typography variant="subtitle1" component="h1" gutterBottom>
                        The value for min. order value will be used regardless
                        of currency for market e.g no currency conversion.
                    </Typography>
                    <FormControl sx={{ width: '22ch' }}>
                        <TextField
                            value={state.minOrderValue}
                            label="Min order value"
                            placeholder="123"
                            variant="outlined"
                            onChange={(event) =>
                                handleStateChange({
                                    minOrderValue: Number(event.target.value),
                                })
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        $
                                    </InputAdornment>
                                ),
                                readOnly: readOnly,
                            }}
                            inputProps={{
                                style: { textAlign: 'right' },
                                'aria-label': '�Minimum Order Value',
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                            }}
                        />
                    </FormControl>
                </Box>

                <Stack spacing={1} direction="row">
                    <FormControl sx={{ width: '22ch' }}>
                        <InputLabel id="promotion-cultureCode-select-label">
                            Culture Code
                        </InputLabel>
                        <Select
                            labelId="promotion-cultureCode-select-label"
                            id="promotion-cultureCode-select"
                            value={selectedCultureCode}
                            label="Languages"
                            onChange={(x) =>
                                setSelectedCultureCode(x.target.value)
                            }
                            readOnly={readOnly}
                            sx={{ height: '7ch' }}
                        >
                            {languages?.map((language) => (
                                <MenuItem key={language.id} value={language.id}>
                                    <ListItemText
                                        primary={
                                            language.name +
                                            ' [' +
                                            language.id +
                                            ']'
                                        }
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: '22ch' }}>
                        <TextField
                            id="promotionName"
                            label="Checkout Name"
                            placeholder="Public"
                            variant="outlined"
                            required
                            onChange={(event) =>
                                handleNameChange(
                                    selectedCultureCode,
                                    event.target.value,
                                )
                            }
                            value={
                                state.cultures.find(
                                    (x) => x.cultureCode == selectedCultureCode,
                                )?.name ?? ''
                            }
                            InputProps={{ readOnly: readOnly }}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '22ch' }}>
                        <TextField
                            id="promotionDescription"
                            label="Checkout Description"
                            placeholder="Public"
                            variant="outlined"
                            required
                            onChange={(event) =>
                                handleDescriptionChange(
                                    selectedCultureCode,
                                    event.target.value,
                                )
                            }
                            value={
                                state.cultures.find(
                                    (x) => x.cultureCode == selectedCultureCode,
                                )?.description ?? ''
                            }
                            InputProps={{ readOnly: readOnly }}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing={1} direction="row">
                    <FormControl sx={{ width: '22ch' }}>
                        <TextField
                            id="internalPromotionName"
                            label="Name"
                            placeholder="Internal"
                            variant="outlined"
                            required
                            onChange={(event) =>
                                handleStateChange({ name: event.target.value })
                            }
                            value={state.name}
                            InputProps={{ readOnly: readOnly }}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '22ch' }}>
                        <TextField
                            id="internalPromotionDescription"
                            label="Description"
                            placeholder="Internal"
                            variant="outlined"
                            onChange={(event) =>
                                handleStateChange({
                                    description: event.target.value,
                                })
                            }
                            value={state.description}
                            InputProps={{ readOnly: readOnly }}
                        />
                    </FormControl>
                </Stack>

                <Box sx={{ paddingTop: 2 }}>
                    <Paper
                        square
                        elevation={3}
                        hidden={
                            selectedCodeGroupId.length == 0 &&
                            (state.codeGroups?.length == 0 ||
                                state.codeGroups == undefined) &&
                            !showCodeGroup
                        }
                    >
                        <Stack
                            spacing={3}
                            sx={{ paddingTop: 0, paddingBottom: 2 }}
                            direction="column"
                        >
                            <FormControl
                                hidden={
                                    state.codeGroups?.length == 0 ||
                                    state.codeGroups == undefined
                                }
                            >
                                <InputLabel id="promotion-codegroup-select-label">
                                    Code groups
                                </InputLabel>
                                <Select
                                    labelId="promotion-codegroup-select-label"
                                    id="promotion-codegroup-select"
                                    value={selectedCodeGroupId}
                                    onChange={(x) => {
                                        setSelectedCodeGroupId(x.target.value);
                                    }}
                                    label="Code group"
                                    readOnly={readOnly}
                                >
                                    <MenuItem value="">
                                        <em>New</em>
                                    </MenuItem>
                                    {state.codeGroups?.map((group) => (
                                        <MenuItem
                                            key={group.id}
                                            value={group.id}
                                        >
                                            <ListItemText
                                                primary={
                                                    group.id +
                                                    ' [IsActive=' +
                                                    group.isActive +
                                                    ',Start=' +
                                                    group?.startDate?.toString() +
                                                    ',End=' +
                                                    group?.endDate?.toString() +
                                                    ']'
                                                }
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div>
                                <Box sx={{ paddingLeft: 1 }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    readOnly={readOnly}
                                                    disabled={readOnly}
                                                    color="success"
                                                    checked={
                                                        selectedCodeGroup?.allowAppendOfAutoGeneratedCodes
                                                    }
                                                    onChange={
                                                        handleAppendAutoGeneratedCodes
                                                    }
                                                />
                                            }
                                            label="Allow autogenerated codes"
                                        />
                                    </FormGroup>
                                    <PromotionLimits
                                        initialState={selectedCodeGroupLimit}
                                        onChange={handleSetCodeGroupLimit}
                                        readOnly={readOnly}
                                    />
                                </Box>
                            </div>
                        </Stack>
                    </Paper>
                </Box>
            </Stack>
        </Container>
    );
};

export default Promotion;
