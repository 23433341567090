import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { settings } from './components/remoteData/APISettings';
import { AuthProvider, getIdToken } from './components/remoteData/authProvider';

const apiSettings = await settings;

export default class HttpClient {
    instance: IPublicClientApplication;
    accounts: AccountInfo[];
    axiosInstance: AxiosInstance;

    constructor(instance?: IPublicClientApplication) {
        this.instance = instance ?? AuthProvider(apiSettings);
        this.accounts = this.instance.getAllAccounts();
        this.axiosInstance = axios.create({
            baseURL: apiSettings.apiBaseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.axiosInstance.interceptors.request.use(
            async (config) => {
                const token = await getIdToken(this.instance, this.accounts);  //Token will be fetched from cache/refreshed per cache policy on acquireTokenSilent
                config.headers['Authorization'] = 'Bearer ' + token;
                return config;
            },
            (error) => Promise.reject(error)
        );

        this.axiosInstance.interceptors.response.use(
            (res) => res,
            (error: AxiosError) => {
                console.log('error', error);
                return Promise.reject(error);
            }
        );
    }

    responseBody = <T>(response: AxiosResponse<T>) => response.data;

    request = <T = any, R = AxiosResponse<T>, D = any>(
        config: AxiosRequestConfig<D>,
    ) => this.axiosInstance.request<T, R, D>(config);
    get = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.get<T, R, D>(url, config);
    delete = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.delete<T, R, D>(url, config);
    head = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.head<T, R, D>(url, config);
    options = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.options<T, R, D>(url, config);
    post = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.post<T, R, D>(url, data, config);
    put = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.put<T, R, D>(url, data, config);
    patch = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.patch<T, R, D>(url, data, config);
    postForm = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.postForm<T, R, D>(url, data, config);
    putForm = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.putForm<T, R, D>(url, data, config);
    patchForm = <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>,
    ) => this.axiosInstance.patchForm<T, R, D>(url, data, config);
}
