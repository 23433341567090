import React, { Component } from 'react';
import Header from './navigation/Header';
export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div id="LayoutDiv">
                <Header />
                <div style={{ marginTop: '64px' }}>{this.props.children}</div>
            </div>
        );
    }
}
