import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import theme from './theme';
//import * as serviceWorker from './registerServiceWorker';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { settings } from './components/remoteData/APISettings';
import { AuthProvider } from './components/remoteData/authProvider';
import { MsalProvider } from '@azure/msal-react';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const apiSettings = await settings;
const auth = AuthProvider(apiSettings);

(async function () {
  ReactDOM.render(
    <MsalProvider instance={auth}>
      <BrowserRouter basename={baseUrl}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
      ,
    </MsalProvider>,
    rootElement,
  );
})();

//serviceWorker.unregister();
