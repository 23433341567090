import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import menuItems from '../Items.json';
import SideNav from '../navigation/SideNav';
import makeStyles from '@mui/styles/makeStyles';
import APIFetcher from '../remoteData/APIFetcher';
import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import { BLKFormComponent } from '../helpcomponents/Forms/FormComponent';
import { BlkCircularProgress } from '../helpcomponents/Progress/CircularProgress';
import { Select, Grid, MenuItem, InputLabel } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    formDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '25px',
        marginRight: '25px',
        marginBottom: '25px',
        minWidth: '60%',

        ['@media screen and (max-width: 900px)']: {
            display: 'block',
        },
    },
    gridForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        ['@media screen and (max-width: 864px)']: {
            display: 'block',
        },
    },
    dropDownStyle: {
        fontSize: '24px',
        marginBottom: '20px',
        border: 'none',
    },
}));

function Reports() {
    const { instance, accounts, inProgress } = useMsal();
    const api = new APIFetcher(instance);
    const location = useLocation();
    const child = location.state;
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [reportData, setReportData] = useState([]);
    const [reportValue, setReportValue] = useState('');
    const [value, setValue] = useState();

    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const shiftSideNav = () => {
        // hide/show sidenav.
        setDrawerOpen(drawerOpen ? false : true);
    };

    const initReactGA = () => {
        ReactGA.initialize('G-X9SBSGYK3H');
        ReactGA.pageview('/laundry/reports');
    };

    useEffect(() => {
        async function onLoad() {
            const reports = await api.getReportsList();
            setReportData(reports.Payload);
            setReportValue(reports.Payload[0]);
            setValue(reports.Payload[0].description);
        }
        onLoad();
        initReactGA();
    }, []);

    function downloadLaundryReport(obj) {
        // function that fetches and downloads the report.
        api.downloadLaundryReport(obj).then(() => setLoading(false));
    }

    const submitForm = async (data) => {
        let obj;
        if (
            reportValue.endPoint !== undefined &&
            (reportValue.parameters === null ||
                reportValue.parameters === undefined)
        ) {
            // Setting reportobj with only endpoint and no parameters.
            obj = { EndPoint: reportValue.endPoint };
        }

        if (
            reportValue.parameters !== null &&
            reportValue.parameters !== undefined &&
            reportValue.parameters.length === 1
        ) {
            // Setting reportobj with one parameter, getting customerdata.
            if (reportValue.parameters[0].query) {
                // Setting reportobj with one parameter that uses query
                obj = { EndPoint: reportValue.endPoint + '?id=' + data.id };
            } else
                obj = {
                    EndPoint: reportValue.endPoint.replace('{id}', data.id),
                }; // Setting reportobj with one parameter where endpoint needs ID.
        }
        if (
            reportValue.parameters !== null &&
            reportValue.parameters !== undefined &&
            reportValue.parameters.length >= 2
        ) {
            // Setting reportobj according to the logicalappobj.
            obj = {
                EndPoint: reportValue.endPoint,
                CompanyCode: data.companycode,
                Description: data.description,
                Fromdate: data.fromdate,
                ReturnFile: true,
                Id: data.companyid,
            };
        }
        setLoading(true);
        downloadLaundryReport(obj);
    };

    const dropDown = () => {
        // dropdown with all the reports.
        function updateReportValue(obj) {
            // keeps the reportDropdown disabled until its populated.
            setValue(obj.description);
            setReportValue(obj);
            return obj;
        }

        return (
            <div className={classes.formDiv}>
                <Grid className={classes.gridForm}>
                    <InputLabel id="market-select-label">
                        Select report
                    </InputLabel>
                    <Select
                        style={{ marginBottom: '20px' }}
                        onChange={(e) =>
                            updateReportValue(
                                reportData.find(
                                    (o) => o.description === e.target.value,
                                ),
                            )
                        }
                        value={value}
                    >
                        {reportData.map(({ description }, index) => (
                            <MenuItem value={description} key={index}>
                                {description}
                            </MenuItem>
                        ))}
                    </Select>
                    <BLKFormComponent
                        parentCallBack={submitForm}
                        {...reportValue}
                    />
                </Grid>
            </div>
        );
    };

    const contentStyle = {
        textAlign: 'center',
        transition: 'margin-left 1000ms cubic-bezier(0.23, 1, 0.32, 1)',
    };
    const instructionsStyle = { justifyContent: 'center', marginTop: '3%' };
    const DropDown = dropDown;
    const storedGroups = JSON.parse(localStorage.getItem('groups'));

    if (storedGroups[3].isMember) {
        //[0] == Admin, [1] == Export, [2] == Import, [3] == Laundry
        if (drawerOpen) {
            contentStyle.marginLeft = 256;
        }
        return (
            <div id="parentDiv" style={{ zIndex: '97' }}>
                <SideNav items={menuItems.Laundry} callback={shiftSideNav} />{' '}
                {/* Gets the sidenav. */}
                <div id="contentDiv" style={contentStyle}>
                    <div id="instructionDiv" style={instructionsStyle}></div>
                    <div id="dropdownDiv" style={{ textAlign: 'center' }}>
                        <DropDown /> {/* Gets the dropdown div. */}
                    </div>
                    <div
                        id="progressDiv"
                        style={{ marginTop: '3%', marginBottom: '3%' }}
                        hidden={!loading}
                    >
                        <BlkCircularProgress />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <h1>
                    YOU DO NOT HAVE ACCESS TO VIEW THE LAUNDRY PAGE. PLEASE
                    CONTACT YOUR ADMINISTRATOR TO GAIN ACCESS
                </h1>{' '}
                {/* Error message thats get printed if you dont have accses. */}
            </div>
        );
    }
}

export default Reports;
