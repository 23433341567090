import { IPublicClientApplication } from '@azure/msal-browser';
import HttpClient from '../http-common';

export class Service {
    client: HttpClient;

    constructor(instance?: IPublicClientApplication) {
        this.client = new HttpClient(instance);
    }
}
