import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

export const getFileName = (
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders,
) => {
    return headers['content-disposition']
        ?.split(';')
        .find((x) => x.includes('filename='))
        ?.replace('filename=', '')
        .trimStart();
};

export const downloadBlob = (
    data: any,
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders,
) => {
    const href = URL.createObjectURL(data);

    // create "a" HTML element with href to file & click. Get Filename from content-disposition header.
    const link = document.createElement('a');
    link.href = href;
    const fileName = getFileName(headers);
    link.setAttribute('download', fileName as any); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};
