import React, { FC, useEffect, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import Grid from '@mui/material/Unstable_Grid2';
import PromotionLimits, {
    PromotionLimitsState,
} from './promotions.limits.component';
import PromotionService from 'services/promotions.service';
import {
    IPromotionCodeResponse,
    IPromotionCultureRequest,
    IPromotionLimitResponse,
    IPromotionResponse,
} from 'types/promotions.type';
import menuItems from 'components/Items.json';

import { useQuery } from '@tanstack/react-query';
import PatchTooltip from 'components/PatchTooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import './promotions.css';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { blue } from '@mui/material/colors';
import {
    Button,
    ButtonGroup,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grow,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
} from '@mui/material';
import Swal from 'sweetalert2';
import { Delete, Edit, Save, Cancel, AddBoxRounded } from '@mui/icons-material';
import Promotion, { PromotionState } from './promotions.promotion.component';
import SideNav from '../navigation/SideNav';
import { useMsal } from '@azure/msal-react';

interface PromotionCodeProps {}

const styles = {
    actionIconButton: {
        marginLeft: 1,
        width: 60,
        height: 60,
        borderRadius: 1,

        '& .MuiSvgIcon-root': {
            fontSize: '200%',
        },
        '&:hover': {
            '& .MuiSvgIcon-root': {
                color: 'white',
            },
            backgroundColor: blue[800],
        },
    },
};

const PromotionCodes: FC<PromotionCodeProps> = () => {
    const { instance, accounts } = useMsal();
    const promotionService = new PromotionService(instance);
    const [drawerOpen, setDrawerOpen] = useState(true);

    const {
        data: codes,
        isError,
        isFetching,
        isLoading,
        refetch,
    } = useQuery<IPromotionCodeResponse[]>(
        ['table-data'],
        async () => {
            const { data } = await promotionService.listCodes();
            return data.sort((a, b) =>
                b.createdBy && a.createdBy
                    ? new Date(b.createdBy.created).getTime() -
                      new Date(a.createdBy.created).getTime()
                    : a.createdBy
                      ? -1
                      : 1,
            );
        },
        { keepPreviousData: false },
    );

    const promotions = useQuery<IPromotionResponse[]>(
        ['promotions'],
        async () => {
            const { data } = await promotionService.listPromotions();
            return data.sort(
                (a, b) =>
                    new Date(b.created).getTime() -
                    new Date(a.created).getTime(),
            );
        },
        { keepPreviousData: false },
    );

    const columns = useMemo<MRT_ColumnDef<IPromotionCodeResponse>[]>(
        () => [
            {
                accessorKey: 'code',
                header: 'Code',
                maxSize: 5,
            },
            {
                accessorKey: 'counter',
                header: 'Used',
                maxSize: 2,
            },
            {
                accessorFn: (row) => row.codeGroup.promotion?.name,
                header: 'Promotion Name',
                maxSize: 5,
            },
            {
                header: 'Created by',
                maxSize: 1,
                accessorFn: (row) => row.createdBy?.customerEmail,
            },
            {
                header: 'Created',
                maxSize: 1,
                accessorFn: (row) => row.createdBy?.created?.toString(),
                //cell: (info: MRT_Cell) => info.getValue<Date>().toLocaleString()
            },
            {
                accessorKey: 'source',
                header: 'Source',
                maxSize: 5,
            },
            {
                //accessorKey: 'startDate',
                header: 'StartDate',
                accessorFn: (row) =>
                    row.startDate > row.codeGroup.startDate
                        ? row.startDate.toString()
                        : row.codeGroup.startDate.toString(),
                maxSize: 5,
            },
            {
                //accessorKey: 'endDate',
                header: 'EndDate',
                accessorFn: (row) =>
                    row.endDate < row.codeGroup.endDate
                        ? row.endDate.toString()
                        : row.codeGroup.endDate.toString(),
                maxSize: 5,
            },
            {
                accessorFn: (row) => row.codeGroup.isActive.toString(),
                header: 'CodeGroup active',
                maxSize: 5,
            },
            {
                accessorFn: (row) =>
                    row.codeGroup.promotion?.isActive?.toString(),
                header: 'Promotion active',
                maxSize: 5,
            },
            {
                accessorKey: 'codeGroup.id',
                header: 'CodeGroup',
                maxSize: 5,
            },
            {
                accessorKey: 'codeGroup.promotionId',
                header: 'PromotionId',
                maxSize: 5,
            },
        ],
        [],
    ) as any;

    const [selectedCode, setSelectedCode] = useState<IPromotionCodeResponse>();
    const [selectedPromotion, setSelectedPromotion] =
        useState<PromotionState>();
    const [selectedCodePromotionId, setSelectedCodePromotionId] =
        useState<string>('');
    const [code, setCode] = useState('');
    const [generateCode, setGenerateCode] = useState(false);
    const [newPromotionWhenNewCode, setNewPromotionWhenNewCode] =
        useState(false);
    const [codeLimit, setCodeLimit] = useState<IPromotionLimitResponse>({
        startDate: new Date(),
        endDate: new Date(),
        limitMarkets: [],
        limitPerCode: 0,
        limitPerEmail: 0,
    });
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

    useEffect(() => {
        setCode(selectedCode?.code ?? '');
        setSelectedCodePromotionId(selectedCode?.codeGroup.promotionId ?? '');
        setCodeLimit(
            (selectedCode as IPromotionLimitResponse) ?? {
                startDate: new Date(),
                endDate: new Date(),
                limitMarkets: [],
                limitPerCode: 0,
                limitPerEmail: 0,
            },
        );
    }, [selectedCode]);

    useEffect(() => {
        if (promotions?.data) {
            setSelectedPromotion(
                promotions?.data.find((x) => x.id == selectedCodePromotionId) ??
                    undefined,
            );
        }
    }, [selectedCodePromotionId]);

    const onCloseRemoveDialog = () => {
        setOpenRemoveDialog(false);
    };

    const createCode = (codeGroupId: string) => {
        return promotionService
            .createCode({
                code,
                limitMarkets: codeLimit.limitMarkets,
                limitPerEmail: codeLimit.limitPerEmail,
                limitPerCode: codeLimit.limitPerCode,
                startDate: codeLimit.startDate,
                endDate: codeLimit.endDate,
                generateCode: generateCode,
                codeGroupId: codeGroupId,
            })
            .then((x) => {
                resetState();
                Swal.fire({
                    icon: 'success',
                    title: 'Saved',
                    showCloseButton: false,
                    showConfirmButton: false,
                    backdrop: true,
                    timer: 2000,
                    toast: true,
                });
            })
            .catch((reason: any) =>
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    showCloseButton: true,
                    text: 'Could not save. Detailed error: ' + reason.response,
                }),
            )
            .finally(() => {
                refetch();
            });
    };

    const saveDiscountCode = async (event) => {
        if (event) {
            event.preventDefault();
            if (!selectedCode?.code) {
                if (selectedPromotion?.selectedCodeGroupId) {
                    await createCode(selectedPromotion?.selectedCodeGroupId);
                } else {
                    const res = await promotionService
                        .createPromotion({
                            discountPercentage:
                                selectedPromotion?.discountPercentage ?? 0,
                            minOrderValue:
                                selectedPromotion?.minOrderValue ?? 0,
                            timeZoneId: 'America/New_York', //EST
                            cultures:
                                selectedPromotion?.cultures as IPromotionCultureRequest[],
                            promotionName: selectedPromotion?.name ?? '',
                            promotionDescription:
                                selectedPromotion?.description ?? '',
                            allowAppendOfAutoGeneratedCodes:
                                selectedCode?.codeGroup
                                    ?.allowAppendOfAutoGeneratedCodes ?? false,
                        })
                        .then((x) => createCode(x.data.codeGroups[0].id))
                        .catch((reason: any) => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                showCloseButton: true,
                                text:
                                    'Could not save. Detailed error: ' +
                                    reason.status +
                                    ': ' +
                                    reason.response,
                            });
                        })
                        .finally(() => {
                            refetch();
                        });
                }
            } else {
                await promotionService
                    .updateCodeLimits(selectedCode.id, {
                        limitMarkets: codeLimit.limitMarkets,
                        limitPerEmail: codeLimit.limitPerEmail,
                        limitPerCode: codeLimit.limitPerCode,
                        startDate: codeLimit.startDate,
                        endDate: codeLimit.endDate,
                    })
                    .then((x) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Updated',
                            showCloseButton: false,
                            showConfirmButton: false,
                            backdrop: true,
                            timer: 2000,
                            toast: true,
                        });
                    })
                    .catch((reason: any) =>
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            showCloseButton: true,
                            text:
                                'Could not save. Detailed error: ' +
                                reason.status +
                                ': ' +
                                reason.response,
                        }),
                    )
                    .finally(() => {
                        refetch();
                    });
            }
        }
    };

    const handleCancelEdit = () => {
        setSelectedCode(undefined);
        Swal.fire({
            icon: 'info',
            title: 'Cancelled',
            showCloseButton: false,
            showConfirmButton: false,
            backdrop: true,
            timer: 2000,
            toast: true,
        });
    };

    const handleCancelCreate = () => {
        Swal.fire({
            icon: 'info',
            title: 'Cancelled',
            showCloseButton: false,
            showConfirmButton: false,
            backdrop: true,
            timer: 2000,
            toast: true,
        });
        resetState();
    };

    const resetState = () => {
        setCode('');
        setSelectedCodePromotionId('');
        setCodeLimit({
            startDate: new Date(),
            endDate: new Date(),
            limitMarkets: [],
            limitPerCode: 0,
            limitPerEmail: 0,
        });
        setNewPromotionWhenNewCode(false);
    };

    const handleSetCodeLimit = (state: PromotionLimitsState) => {
        setCodeLimit({
            limitMarkets: state.selectedMarkets,
            limitPerEmail: state.limitOncePerEmail ? 1 : 0,
            limitPerCode: state.singleUsage ? 1 : 0,
            startDate: state.startDate.toDate(),
            endDate: state.endDate.toDate(),
        });
    };

    return (
        <div>
            <SideNav
                items={menuItems.Promotions as any}
                callback={(changeStatus: boolean) =>
                    setDrawerOpen(changeStatus)
                }
            />
            <Container
                maxWidth="lg"
                sx={{ marginLeft: drawerOpen ? '256px' : '' }}
            >
                <Grid container spacing={3}>
                    <Grid xs={6}>
                        <Box sx={{ my: 4 }}>
                            <Typography
                                variant="h4"
                                component="h1"
                                gutterBottom
                            >
                                {selectedCode?.code
                                    ? 'Edit ' + selectedCode.code
                                    : 'Create'}
                            </Typography>
                            <Paper elevation={3}>
                                <form
                                    onSubmit={async (event) =>
                                        await saveDiscountCode(event)
                                    }
                                    autoComplete="off"
                                >
                                    <Stack
                                        spacing={3}
                                        sx={{ paddingLeft: 2, paddingTop: 2 }}
                                    >
                                        <Collapse
                                            in={
                                                selectedCode?.code
                                                    ? false
                                                    : true
                                            }
                                        >
                                            <Stack direction="row" spacing={1}>
                                                <FormControl
                                                    sx={{ width: '50ch' }}
                                                >
                                                    <TextField
                                                        id="code"
                                                        label="Code"
                                                        placeholder="Words or letters"
                                                        variant="outlined"
                                                        required={!generateCode}
                                                        disabled={generateCode}
                                                        onChange={(event) =>
                                                            setCode(
                                                                event.target
                                                                    .value,
                                                            )
                                                        }
                                                        value={code}
                                                    />
                                                </FormControl>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            value={generateCode}
                                                            onChange={(event) =>
                                                                setGenerateCode(
                                                                    event.target
                                                                        .checked,
                                                                )
                                                            }
                                                            disabled={
                                                                code.trim()
                                                                    .length != 0
                                                            }
                                                            sx={{
                                                                color: blue[700],
                                                                '&.Mui-checked':
                                                                    {
                                                                        color: blue[700],
                                                                    },
                                                            }}
                                                        />
                                                    }
                                                    label="Generate Code"
                                                />
                                            </Stack>
                                        </Collapse>
                                        <Box>
                                            <Typography
                                                variant="subtitle1"
                                                component="h1"
                                                gutterBottom
                                            >
                                                Code limits will override
                                                promotion limits.
                                            </Typography>
                                            <PromotionLimits
                                                initialState={codeLimit}
                                                onChange={handleSetCodeLimit}
                                            />
                                        </Box>
                                        <Stack direction="row" spacing={1}>
                                            <FormControl sx={{ width: '50ch' }}>
                                                <InputLabel id="promotion-code-select-label">
                                                    Promotions
                                                </InputLabel>
                                                <Select
                                                    labelId="promotion-code-select-label"
                                                    id="promotion-code-select"
                                                    value={
                                                        selectedCodePromotionId
                                                    }
                                                    label="Promotion"
                                                    disabled={
                                                        newPromotionWhenNewCode
                                                    }
                                                    required={
                                                        !newPromotionWhenNewCode
                                                    }
                                                    onChange={(x) =>
                                                        setSelectedCodePromotionId(
                                                            x.target.value,
                                                        )
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        None
                                                    </MenuItem>
                                                    {promotions.data?.map(
                                                        (promotion) => (
                                                            <MenuItem
                                                                key={
                                                                    promotion.id
                                                                }
                                                                value={
                                                                    promotion.id
                                                                }
                                                            >
                                                                <ListItemText
                                                                    primary={`${promotion.name} (${promotion.description ?? ''})} ${promotion.id}`}
                                                                />
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        value={
                                                            newPromotionWhenNewCode
                                                        }
                                                        onChange={(event) =>
                                                            setNewPromotionWhenNewCode(
                                                                event.target
                                                                    .checked,
                                                            )
                                                        }
                                                        disabled={
                                                            selectedCodePromotionId?.length !=
                                                            0
                                                        }
                                                        sx={{
                                                            color: blue[700],
                                                            '&.Mui-checked': {
                                                                color: blue[700],
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="New promotion"
                                            />
                                        </Stack>
                                        <Grow
                                            in={
                                                newPromotionWhenNewCode ||
                                                selectedCodePromotionId?.length >
                                                    0
                                            }
                                        >
                                            <Box>
                                                <Paper elevation={3}>
                                                    <Promotion
                                                        id={
                                                            selectedCodePromotionId
                                                        }
                                                        onChange={
                                                            setSelectedPromotion
                                                        }
                                                        readOnly={
                                                            selectedCode?.code
                                                                ? true
                                                                : false ||
                                                                  selectedCodePromotionId?.length >
                                                                      0
                                                        }
                                                    ></Promotion>
                                                </Paper>
                                            </Box>
                                        </Grow>
                                        <Box
                                            m={1}
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="flex-end"
                                        >
                                            <ButtonGroup
                                                variant="outlined"
                                                aria-label="outlined primary button group"
                                                sx={{
                                                    paddingBottom: 1,
                                                    paddingRight: 1,
                                                }}
                                            >
                                                {selectedCode?.code ? (
                                                    <Box>
                                                        <PatchTooltip title="Cancel">
                                                            <Fab
                                                                color="default"
                                                                size="large"
                                                                sx={
                                                                    styles.actionIconButton
                                                                }
                                                                onClick={
                                                                    handleCancelEdit
                                                                }
                                                            >
                                                                <Cancel />
                                                            </Fab>
                                                        </PatchTooltip>
                                                        <PatchTooltip title="Delete">
                                                            <Fab
                                                                color="error"
                                                                size="large"
                                                                sx={
                                                                    styles.actionIconButton
                                                                }
                                                                onClick={() =>
                                                                    setOpenRemoveDialog(
                                                                        true,
                                                                    )
                                                                }
                                                            >
                                                                <Delete />
                                                            </Fab>
                                                        </PatchTooltip>
                                                        <PatchTooltip title="Save">
                                                            <Fab
                                                                color="primary"
                                                                size="large"
                                                                sx={[
                                                                    {
                                                                        backgroundColor:
                                                                            blue[400],
                                                                    },
                                                                    styles.actionIconButton,
                                                                ]}
                                                                type="submit"
                                                            >
                                                                <Save />
                                                            </Fab>
                                                        </PatchTooltip>
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        <PatchTooltip title="Cancel">
                                                            <Fab
                                                                color="inherit"
                                                                size="large"
                                                                sx={
                                                                    styles.actionIconButton
                                                                }
                                                                onClick={
                                                                    handleCancelCreate
                                                                }
                                                            >
                                                                <Cancel />
                                                            </Fab>
                                                        </PatchTooltip>
                                                        <PatchTooltip title="Add">
                                                            <Fab
                                                                color="primary"
                                                                size="large"
                                                                sx={[
                                                                    {
                                                                        backgroundColor:
                                                                            blue[400],
                                                                    },
                                                                    styles.actionIconButton,
                                                                ]}
                                                                type="submit"
                                                            >
                                                                <AddBoxRounded />
                                                            </Fab>
                                                        </PatchTooltip>
                                                    </Box>
                                                )}
                                            </ButtonGroup>
                                        </Box>
                                    </Stack>
                                </form>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid xs={6}>
                        <Box sx={{ my: 4 }}>
                            <Typography
                                variant="h4"
                                component="h1"
                                gutterBottom
                            >
                                Codes
                            </Typography>
                            <Dialog
                                open={openRemoveDialog}
                                onClose={onCloseRemoveDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Delete
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Do you want to delete{' '}
                                        {selectedCode?.code} ?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        color="info"
                                        onClick={onCloseRemoveDialog}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        onClick={async () => {
                                            await promotionService
                                                .deleteCode(selectedCode?.id)
                                                .then((x) =>
                                                    Swal.fire({
                                                        icon: 'warning',
                                                        title: 'Deleted',
                                                        showCloseButton: false,
                                                        showConfirmButton:
                                                            false,
                                                        backdrop: true,
                                                        timer: 2000,
                                                        toast: true,
                                                    }),
                                                );
                                            setSelectedCode(undefined);
                                            onCloseRemoveDialog();
                                            refetch();
                                        }}
                                        autoFocus
                                    >
                                        Yes
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <MaterialReactTable
                                enableRowActions
                                renderRowActionMenuItems={({
                                    row,
                                    closeMenu,
                                }) => [
                                    <MenuItem
                                        key={row.id + '1'}
                                        onClick={() => {
                                            setSelectedCode(row.original);
                                            closeMenu();
                                        }}
                                    >
                                        <Edit /> Edit
                                    </MenuItem>,
                                    <MenuItem
                                        key={row.id + '2'}
                                        onClick={() => {
                                            setSelectedCode(row.original);
                                            closeMenu();
                                            setOpenRemoveDialog(true);
                                        }}
                                    >
                                        <Delete color="error" /> Remove
                                    </MenuItem>,
                                ]}
                                columns={columns}
                                data={codes ?? []}
                                initialState={{
                                    columnPinning: { left: ['code'] },
                                    density: 'compact',
                                    showGlobalFilter: true,
                                    pagination: { pageIndex: 0, pageSize: 25 },
                                }}
                                enableRowNumbers={false}
                                enableBottomToolbar={true}
                                enableMultiRowSelection={false}
                                enableHiding={false}
                                muiTableContainerProps={{
                                    sx: {
                                        maxHeight: '375px',
                                        minHeight: '375px',
                                    },
                                }}
                                enableStickyHeader
                                getRowId={(row) => row.id}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                              color: 'error',
                                              children: 'Error loading data',
                                          }
                                        : undefined
                                }
                                renderTopToolbarCustomActions={() => (
                                    <PatchTooltip arrow title="Refresh Data">
                                        <IconButton onClick={() => refetch()}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </PatchTooltip>
                                )}
                                rowCount={codes?.length ?? 0}
                                manualSorting
                                state={{
                                    columnVisibility: {
                                        'mrt-row-select': false,
                                    },
                                    isLoading,
                                    showAlertBanner: isError,
                                    showProgressBars: isFetching,
                                }}
                                enableDensityToggle={false}
                                memoMode="rows"
                                enableSorting={false}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};
export default PromotionCodes;
