import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import ReactGA from 'react-ga';
import { Navigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import {
    createTheme,
    ThemeProvider,
    Theme,
    StyledEngineProvider,
} from '@mui/material/styles';
import './custom.css';
import { BlkCircularProgress } from './components/helpcomponents/Progress/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ErrorBoundary from './error-boundry';
import PromotionCodes from './components/promotions/promotions.codes.component';
import {
    MsalAuthenticationTemplate,
    UnauthenticatedTemplate,
    useMsalAuthentication,
} from '@azure/msal-react';
import { loginRequest } from './components/remoteData/authProvider';
import { InteractionType } from '@azure/msal-browser';

import ImportUsersByEmail from './components/laundry/ImportUsersByEmail';
import ImportByExcel from './components/import/ImportByExcel';
import UserImportStatus from './components/import/UserImportStatus';
import ListImportStatus from './components/laundry/ListImportStatus';
import ImportFromCompany from './components/laundry/ImportFromCompany';
import Reports from './components/laundry/Reports';
import CreateStormImportfile from './components/import/CreateStormImportfile';

/* //Keeping this if we should try to add additional components/pages to be lazied
import NotFound from 'components/NotFound';
import PromotionsReport from 'components/promotions/promotions.reports.component';
import { Home } from '@mui/icons-material';
import Promotions from 'components/promotions/promotions.component';
import BMECAT from 'components/export/BMECAT';
import EditBusinessArea from 'components/admin/EditBusinessArea';
import EditRoles from 'components/admin/EditRoles';
import EditUser from 'components/admin/EditUser';
import Products from 'components/export/products';
import Notifications from 'components/notifications/notifications.component';
//const ImportUsersByEmail = lazy(() => import('./components/laundry/ImportUsersByEmail'));
//const ImportByExcel = lazy(() => import('./components/import/ImportByExcel'));
//const UserImportStatus = lazy(() => import('./components/import/UserImportStatus'));
//const ListImportStatus = lazy(() => import('./components/laundry/ListImportStatus'));
//const ImportFromCompany = lazy(() => import('./components/laundry/ImportFromCompany'));
//const Reports = lazy(() => import('./components/laundry/Reports'));
//const CreateStormImportfile = lazy(() => import('./components/import/CreateStormImportfile'));
*/

const queryClient = new QueryClient();
const EditUser = lazy(() => import('./components/admin/EditUser'));
const EditRoles = lazy(() => import('./components/admin/EditRoles'));
const EditBusinessArea = lazy(
    () => import('./components/admin/EditBusinessArea'),
);
const BMECAT = lazy(() => import('./components/export/BMECAT'));
const ProductExport = lazy(() => import('./components/export/products'));
const NotFound = lazy(() => import('./components/NotFound'));
const Promotions = lazy(
    () => import('./components/promotions/promotions.component'),
);
const PromotionsReport = lazy(
    () => import('./components/promotions/promotions.reports.component'),
);
const Notifications = lazy(
    () => import('./components/notifications/notifications.component'),
);
const AISearch = lazy(() => import('./components/aiSearch/AISearch'));
const Hangtight = lazy(() => import('./components/hangtight/Hangtight'));
const HangtightEvents = lazy(
    () => import('./components/hangtight/events/HangtightEvents'),
);
const HangtightRegistrations = lazy(
    () => import('./components/hangtight/registrations/HangtightRegistrations'),
);
const HangtightCSVExport = lazy(
    () => import('./components/hangtight/registrations/HangtightCSVExport'),
);
const Home = lazy(() => import('./components/Home'));
const authRequest = {
    ...loginRequest,
};

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

const blkTheme = createTheme({
    palette: {
        primary: {
            main: '#0d1523',
            light: '#121e31',
        },
        secondary: {
            main: '#ffe002',
            light: '#f6f6f6',
        },
    },
    typography: {
        fontFamily: ['"Open Sans"', 'Stratum1Bold', 'Roboto'].join(','),
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: '#0d1523',
                    color: 'white',
                    top: 'inherit', //Sidenav starts beneath topnavbar
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'white',
                        borderRadius: 10,
                    },
                    '&:active': {
                        backgroundColor: 'white',
                        borderRadius: 10,
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    '.Mui-disabled': { opacity: 0.5 },
                    '.MuiTypography-body1': {
                        paddingLeft: '45px',
                    },
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    color: 'white !important',
                    '&:hover': {
                        color: 'white !important',
                    },
                    '&.Mui-active': {
                        color: 'white !important',
                    },
                },
                icon: {
                    color: 'white !important',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: 'black',
                    '&.Mui-focused': {
                        color: 'black',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    border: '0px',
                    outline: 'none',
                    '&:active': {
                        outline: 'none',
                        border: '0px',
                    },
                    '&:hover': {
                        outline: 'none',
                        border: '0px',
                        backgroundColor: 'transparent',
                    },
                    '&:focus': {
                        outline: 'none',
                        border: '0px',
                    },
                },
            },
        },
    },
});

const App: React.FC = () => {
    const { login, result, error } = useMsalAuthentication(
        InteractionType.Redirect,
        authRequest,
    );

    useEffect(() => {
        ReactGA.initialize('G-X9SBSGYK3H');
        ReactGA.pageview('App');
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
            >
                <StyledEngineProvider injectFirst>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={blkTheme}>
                            <Layout>
                                <ErrorBoundary>
                                    <Suspense
                                        fallback={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '200px',
                                                }}
                                            >
                                                <BlkCircularProgress />
                                            </div>
                                        }
                                    >
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={<Home />}
                                            />
                                            <Route
                                                path="/admin/users"
                                                element={
                                                    <EditUser
                                                        key={Date.now()}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/admin/roles"
                                                element={
                                                    <EditRoles
                                                        key={Date.now()}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/admin/businessareas"
                                                element={
                                                    <EditBusinessArea
                                                        key={Date.now()}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/export/bmecat"
                                                element={
                                                    <BMECAT key={Date.now()} />
                                                }
                                            />
                                            <Route
                                                path="/import/importbyexcel"
                                                element={
                                                    <ImportByExcel
                                                        key={Date.now()}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/import/userimportstatus"
                                                element={
                                                    <UserImportStatus
                                                        key={Date.now()}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/import/createstormimportfile"
                                                element={
                                                    <CreateStormImportfile
                                                        key={Date.now()}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/laundry/importbyemail"
                                                element={
                                                    <ImportUsersByEmail
                                                        key={Date.now()}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/laundry/importfromcompany"
                                                element={
                                                    <ImportFromCompany
                                                        key={Date.now()}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/laundry/listimportstatus"
                                                element={
                                                    <ListImportStatus
                                                        key={Date.now()}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/laundry/reports"
                                                element={<Reports />}
                                            />
                                            <Route
                                                path="/import"
                                                element={
                                                    <Navigate to="/import/importbyexcel" />
                                                }
                                            />
                                            <Route
                                                path="/laundry"
                                                element={
                                                    <Navigate to="/laundry/importbyemail" />
                                                }
                                            />
                                            <Route
                                                path="/admin"
                                                element={
                                                    <Navigate to="/admin/users" />
                                                }
                                            />
                                            <Route
                                                path="/export"
                                                element={
                                                    <Navigate to="/export/products" />
                                                }
                                            />
                                            <Route
                                                path="/export/products"
                                                element={<ProductExport />}
                                            />
                                            <Route
                                                path="/promotions"
                                                element={<Promotions />}
                                            />
                                            <Route
                                                path="/promotions/codes"
                                                element={<PromotionCodes />}
                                            />
                                            <Route
                                                path="/promotions/report"
                                                element={<PromotionsReport />}
                                            />
                                            <Route
                                                path="/notifications"
                                                element={<Notifications />}
                                            />
                                            <Route
                                                path="/hangtight"
                                                element={<Hangtight />}
                                            >
                                                <Route
                                                    index
                                                    element={
                                                        <Navigate to="/hangtight/events" />
                                                    }
                                                />
                                                <Route
                                                    path="events"
                                                    element={
                                                        <HangtightEvents />
                                                    }
                                                />
                                                <Route
                                                    path="registrations"
                                                    element={
                                                        <HangtightRegistrations />
                                                    }
                                                />
                                                <Route
                                                    path="export"
                                                    element={
                                                        <HangtightCSVExport />
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path="/aisearch"
                                                element={<AISearch />}
                                            />
                                            <Route
                                                path="*"
                                                element={<NotFound />}
                                            />
                                        </Routes>
                                    </Suspense>
                                </ErrorBoundary>
                            </Layout>
                        </ThemeProvider>
                    </QueryClientProvider>
                </StyledEngineProvider>
            </MsalAuthenticationTemplate>
            <UnauthenticatedTemplate>
                <span>You do not have access. Redirecting to login... </span>
            </UnauthenticatedTemplate>
        </LocalizationProvider>
    );
};

export default App;
