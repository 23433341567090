import {
    AccountInfo,
    Configuration,
    LogLevel,
    IPublicClientApplication,
    PublicClientApplication,
    RedirectRequest,
} from '@azure/msal-browser';
import { ApiSettings } from './APISettings';

function AuthProvider(settings: ApiSettings) {
    const config: Configuration = {
        auth: {
            authority: settings.authority,
            clientId: settings.clientId,
            redirectUri: settings.redirect,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
            secureCookies: false,
        },
        system: {
            loggerOptions: {
                logLevel: LogLevel.Verbose,
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            //console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                },
                piiLoggingEnabled: false,
            },
        },
    };

    return new PublicClientApplication(config);
}

export { AuthProvider };

export const loginRequest: RedirectRequest = {
    scopes: ['User.Read'],
};

export async function getIdToken(
    instance: IPublicClientApplication,
    accounts: AccountInfo[],
) {
    if (accounts.length > 0) {
        const exp = accounts[0].idTokenClaims?.exp as number | null;

        const currentTime = Math.floor(Date.now() / 1000); // Convert to Unix timestamp
        const forceRefresh = exp != null && currentTime > exp;
        const request = {
            scopes: ['User.Read'],
            account: accounts[0],
            forceRefresh: forceRefresh,
        };

        return await instance
            .acquireTokenSilent(request)
            .then((response) => {
                return response.idToken;
            })
            .catch(async (error) => {
                console.warn('acquireTokenSilent failed', error);
                return await instance
                    .acquireTokenPopup(request)
                    .then((response) => {
                        return response.idToken;
                    })
                    .catch(async function (error) {
                        console.warn(
                            'acquireTokenPopup failed logging out',
                            error,
                        );
                        return await instance.logoutRedirect({
                            account: accounts[0],
                        });
                    });
            });
    }

    return '';
}
