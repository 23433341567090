import {
    IPromotionCodeResponse,
    IPromotionCodeCreateRequest,
    IPromotionResponse,
    IPromotionLimitRequest,
    IPromotionCodeLimitResponse,
    IPromotionCodeGroupLimitResponse,
    IPromotionUpdateRequest,
    IPromotionCreateRequest,
} from '../types/promotions.type';
import { Service } from './Service';

export default class PromotionsService extends Service {
    getPromotion(id: string) {
        return this.client.get<IPromotionResponse>(`promotion/${id}`);
    }

    updatePromotion(id: string, request: IPromotionUpdateRequest) {
        return this.client.put<IPromotionResponse>(`promotion/${id}`, request);
    }

    createPromotion(data: IPromotionCreateRequest) {
        return this.client.post<IPromotionResponse>('promotion', data);
    }

    deletePromotion(id: string) {
        return this.client.delete(`promotion/${id}`);
    }

    listCodes() {
        return this.client.get<Array<IPromotionCodeResponse>>(
            'promotion/codes',
        );
    }

    listPromotions() {
        return this.client.get<Array<IPromotionResponse>>('promotion');
    }

    createCode(data: IPromotionCodeCreateRequest) {
        return this.client.post<IPromotionCodeResponse>(
            'promotion/codes',
            data,
        );
    }

    updateCodeLimits(id: string, data: IPromotionLimitRequest) {
        return this.client.put<IPromotionCodeLimitResponse[]>(
            `promotion/codes/${id}/limits`,
            data,
        );
    }

    updateCodeGroupLimits(id: string, data: IPromotionLimitRequest) {
        return this.client.put<IPromotionCodeGroupLimitResponse[]>(
            `promotion/codegroups/${id}/limits`,
            data,
        );
    }

    updateCodeGroupActive(id: string, active: boolean) {
        return this.client.put(`promotion/codegroups/${id}/active/${active}`);
    }

    updateCodeGroupAllowAppendOfAutoGeneratedCodes(
        id: string,
        active: boolean,
    ) {
        return this.client.put(
            `promotion/codegroups/${id}/AllowAppendOfAutoGeneratedCodes/${active}`,
        );
    }

    deleteCode(id?: string) {
        return this.client.delete(`promotion/codes/${id}`);
    }

    downloadPromotionCodeReport(id: string) {
        return this.client.get(`promotion/${id}/report`, {
            responseType: 'blob',
        });
    }

    /* updateCode(id: string, data: IPromotionCodeRequest) {
     return this.client.put<IPromotionCodeRequest>(`promotion/codes/${id}`, data);
 }*/
}
