import { ILookupResponse } from './lookups.type';

export interface IDownloadProductsRequest {
    priceListId: number;
    cultureCode: string;
    marketId: string;
    lifeCycle: string[];
    includeHidden: boolean;
    includeItemsWithoutPrice: boolean;
    skipFilterOnPriceListId: boolean;
    format: string;
    companyId?: number;
    sizeCultureCode: string;
    formatType: string;
    status: string[];
}

export interface IDownloadProductsState {
    priceListId?: ILookupResponse | null;
    cultureCode: string;
    marketId: string;
    lifeCycle: string[];
    includeHidden: boolean;
    includeItemsWithoutPrice: boolean;
    skipFilterOnPriceListId: boolean;
    format: string;
    formatType: string;
    companyId?: ILookupResponse | null;
    sizeCultureCode: string;
    status: string[];
}

export enum LifeCycle {
    Unknown = 0,
    New = 1,
    OnItsWayOrOrdered = 2,
    InStock = 3,
    Outgoing = 4,
    Expired = 5,
}

export enum StatusFlag {
    NEW_COLOUR = 'NewColour',
    NEW = 'New',
    A1 = 'OutgoingColor',
    A2 = 'OutgoingProduct',
    AN1 = 'NewsInStockAW',
    AN2 = 'NewsOnWebNotInStock',
    AN3 = 'NewsNowInStock',
    A3 = 'PlannedOutgoing3',
    CA = 'CanadaOnly',
    D = 'TempStatus',
    K = 'CatalogStandard',
    L = 'LimitedTime',
    N = 'NewOnlyToWorkbook',
    N1 = 'N1',
    N2 = 'NewArticles',
    NUS = 'NewsForUSAndCanada',
    P = 'DeadArticle',
    PR = 'AllNCArticles',
    S = 'SpecialForContracts',
    S1 = 'SpecialForContracts1',
    SS = 'SpecialForContracts2',
    SN1 = 'NewsInStockSS',
    SN2 = 'NewsOnWebNotInStockSS',
    SN3 = 'NewsNowInStockSS',
    U = 'UnderDevelopment',
    US = 'USAProduct',
    XWARM = 'ExtraWarm',
    WARM = 'Warm',
    SAFE = 'Safe',
    LIGHT = 'Light',
    DRY = 'Dry',
    Canada = 'CanadaProduct',
}

export type ProductSearchParams = {
    query: string;
    marketId: string;
    cultureCode: string;
};

export type ProductSearchResponseItem = {
    id: string;
    partNo: string;
    name: string;
    description: string;
    imageUrl: string;
};

export type ProductSearchResponse = {
    isValid: boolean;
    errorMessage: string;
    total: number;
    results: ProductSearchResponseItem[];
};
