import React, { Component } from 'react';
import ReactGA from 'react-ga';
import menuItems from '../Items.json';
import SideNav from '../navigation/SideNav';
import GetAppIcon from '@mui/icons-material/GetApp';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import CssBaseline from '@mui/material/CssBaseline';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MenuItem, Grid } from '@mui/material';
import { BlkCircularProgress } from '../helpcomponents/Progress/CircularProgress';
import { withMsal } from '@azure/msal-react';

import FormControlLabel from '@mui/material/FormControlLabel';
import APIFetcher from '../remoteData/APIFetcher';
import Checkbox from '@mui/material/Checkbox';
import { BlkMaterialTable } from '../helpcomponents/Tables/MaterialTable';

import Description from '../helpcomponents/Descriptions/Description';

const useStyles = (theme) => ({
    inputFormStyle: {
        minWidth: '40vw',
        maxWidth: '50%',
        borderRadius: 15,
        padding: '30px',
        backgroundColor: '#E0E0E0',
        boxSizing: 'border-box',
        color: 'black',
        ['@media screen and (max-width: 900px)']: {
            maxWidth: '100% !important',
        },
    },
    formDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '60px',
    },
    input: {
        color: 'black', //fontcolor for labels and selects
        fontSize: 14,
        borderRadius: '10px',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    inputBorder: {
        color: 'grey !important', //fontcolor to placeholder in form
        fontSize: 14,
        borderRadius: 25,
    },
    formselect: {
        maxMenuHeight: 250,
        height: '55px', //Same height for all formcontrols. (SELECT & TextField)
        color: 'black',
        border: '2px solid grey',
        borderRadius: '5px',
    },
    formControl: {
        margin: theme.spacing(1),
        color: 'black',
        borderRadius: '10px',
        backgroundColor: 'white',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            //border to TextField in form
            borderColor: 'black',
        },
    },
    icon: {
        color: 'black',
    },
    submitButton: {
        backgroundColor: '#0d1523',
        color: 'white',
        marginTop: '5%',
        marginBottom: '1%',
        maxWidth: '97%',
        fontSize: 20,
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
    fetchInfo: {
        width: '100%',
        display: 'flex',
        columnGap: '30px',
    },
    btn: {
        backgroundColor: '#0d1523',
        color: 'white',
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
    abortButton: {
        position: 'absolute',
        left: '40%',
        width: '90px',
        height: '30px',
        top: '80%',
        borderRadius: 15,
        padding: '0 30px',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'darkred',
        },
    },
});

const companyOptionsList = require('./companyOptionsList.json');

class UserImportStatusComponent extends Component {
    static displayName = UserImportStatusComponent.name.toUpperCase();
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            loading: false,
            companyListOptions: [],
            CompanyId: '',
            WA_TransactionId: '',
            EmailAddress: '',
            Status: '',
            Include_Ids: false,
            Created: '',
            responseData: {},
            dataPosted: false,
            drawerOpen: true,
            btnDisabled: false,
            descriptionAndHelpHandler: {
                name: 'UserImportStatus',
                func: this.setGetHelp,
                helpDiv: true,
            },
        };
        this.onChange = this.onChange.bind(this);
        this.api = new APIFetcher(props.msalContext.instance);
    }

    initReactGA = () => {
        ReactGA.initialize('G-X9SBSGYK3H');
        ReactGA.pageview('/import/userimportstatus');
    };

    componentDidMount() {
        this.initReactGA();
        const companyOptions = companyOptionsList.map((item) => ({
            key: item.id,
            label: item.name,
            value: item.name,
        }));

        this.setState({ companyListOptions: companyOptions });
    }

    curday = function (sp) {
        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //As January is 0.
        const yyyy = today.getFullYear();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        return yyyy + sp + mm + sp + dd;
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    async getUserImportStatus(obj) {
        this.setState({ loading: true });
        const response = await this.api.getUserImportStatus(obj);
        this.setState({ loading: false });
        if (response !== '500' && response !== '204' && response !== null) {
            const columns = [
                { title: 'CompanyId', field: 'CompanyId' },
                { title: 'Created', field: 'Created' },
                { title: 'Email', field: 'Email' },
                { title: 'MailSent', field: 'MailSent' },
                { title: 'Status', field: 'Status' },
            ];
            const data = {
                columns: columns,
                data: response.ResultSets.Table1,
                title: this.state.Created,
            };
            this.setState({ dataPosted: true, tableData: data });
        } else if (
            response === '204' ||
            Object.keys(response.ResultSets).length === 0 ||
            response === null
        ) {
            Swal.fire({
                icon: 'info',
                showCloseButton: true,
                title: 'NOT FOUND',
                text: 'Could not find any data based on your parameters',
            }).then(() => {
                window.location.reload();
            });
        } else {
            Swal.fire({
                icon: 'error',
                showCloseButton: true,
                title: 'error',
                text: 'Something went wrong.. Contact Admin if this occurs again.',
            }).then(() => {
                window.location.reload();
            });
        }
    }

    postData = (e) => {
        e.preventDefault();

        const data = new FormData(e.target);
        data.get('Created') === ''
            ? this.setState({ Created: this.curday('-') })
            : this.setState({ Created: data.get('Created') });
        this.setState({ loading: true, btnDisabled: !this.state.btnDisabled });
        const obj = {
            CompanyId: data.get('CompanyId'),
            WA_TransactionId: data.get('WA_TransactionId'),
            EmailAddress: data.get('EmailAddress'),
            Status: data.get('Status'),
            Include_Ids: this.state.Include_Ids,
            Created:
                data.get('Created') === ''
                    ? this.curday('-')
                    : data.get('Created'),
            ResponseAsJson: '1',
        }; //Created from state because we modify the value if no date was chosen.
        this.getUserImportStatus(obj);
    };

    displayResponse = () => {
        return <BlkMaterialTable {...this.state.tableData} />;
    };

    checkNewTransactions = () => {
        const { classes } = this.props;

        return (
            <Button
                className={classes.btn}
                onClick={() => {
                    this.setState({
                        dataPosted: !this.state.dataPosted,
                        btnDisabled: !this.state.btnDisabled,
                    });
                }}
            >
                Check New
            </Button>
        );
    };

    shiftSideNav = (changeStatus) => {
        this.setState({ drawerOpen: changeStatus });
    };

    inputForm = () => {
        const { classes } = this.props;

        const handleCheckChange = () => {
            this.setState({ Include_Ids: !this.state.Include_Ids });
        };

        const statusOptions = [
            { value: 'Succeeded', label: 'Succeeded' }, //Value: Succeded should be 'Succeeded'   Needs to be changed in API.
            { value: 'Failed', label: 'Failed' },
            { value: '', label: 'NONE' },
        ];

        return (
            <Container>
                <CssBaseline />
                <div className={classes.formDiv}>
                    <form
                        className={classes.inputFormStyle}
                        autoComplete="off"
                        onSubmit={this.postData}
                    >
                        <Grid container justifyContent="center">
                            <Typography
                                style={{ marginBottom: '3%', fontSize: '3vw' }}
                                component="h1"
                                variant="h3"
                            >
                                USER IMPORT STATUS
                            </Typography>
                            <FormControl
                                className={classes.formControl}
                                required
                                fullWidth
                            >
                                <TextField
                                    select
                                    label="CompanyId"
                                    value={this.state.CompanyId}
                                    name="CompanyId"
                                    onChange={this.onChange}
                                    SelectProps={{
                                        classes: { icon: classes.icon },
                                        native: false,
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputBorder,
                                    }}
                                    variant="filled"
                                    required
                                >
                                    {this.state.companyListOptions.map(
                                        (option) => (
                                            <MenuItem
                                                key={option.key}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ),
                                    )}
                                </TextField>
                            </FormControl>
                            <br />
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    InputProps={{
                                        className: classes.input,
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputBorder,
                                    }}
                                    variant="filled"
                                    type="text"
                                    name="WA_TransactionId"
                                    value={this.state.WA_TransactionId}
                                    onChange={this.onChange}
                                    label="WA_TransactionId"
                                />
                            </FormControl>
                            <br />
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    InputProps={{
                                        autoComplete: classes.input,
                                        className: classes.input,
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputBorder,
                                    }}
                                    variant="filled"
                                    type="text"
                                    name="EmailAddress"
                                    value={this.state.EmailAddress}
                                    onChange={this.onChange}
                                    label="EmailAddress"
                                />
                            </FormControl>
                            <br />
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    select
                                    label="Status"
                                    value={this.state.Status}
                                    defaultValue=""
                                    name="Status"
                                    onChange={this.onChange}
                                    SelectProps={{
                                        classes: { icon: classes.icon },
                                        native: false,
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputBorder,
                                    }}
                                    variant="filled"
                                >
                                    {statusOptions.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {/* <TextField InputProps={{ className: classes.input, disableUnderline: true }} InputLabelProps={{ className: classes.inputBorder }} variant="filled" type="text" name="Status" value={this.state.Status} onChange={this.onChange} label="Status" /> */}
                            </FormControl>
                            <br />
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <FormControlLabel
                                    style={{
                                        marginLeft: '10px',
                                        marginTop: '10px',
                                    }}
                                    name="Include_Ids"
                                    label="Include_Ids"
                                    control={
                                        <Checkbox
                                            value={this.state.Include_Ids}
                                            checked={this.state.Include_Ids}
                                            onChange={handleCheckChange}
                                        />
                                    }
                                />
                                {/*  <TextField InputProps={{ className: classes.input, disableUnderline: true }} InputLabelProps={{ className: classes.inputBorder }} variant="filled" type="text" name="Include_Ids" value={this.state.Include_Ids} onChange={this.onChange} label="Include_Ids" /> */}
                            </FormControl>
                            <br />
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    InputProps={{
                                        className: classes.input,
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputBorder,
                                    }}
                                    variant="filled"
                                    type="date"
                                    defaultValue={this.curday('-')}
                                    name="Created"
                                    /* value={this.state.Created} */ onChange={
                                        this.onChange
                                    }
                                    label="Created"
                                />
                            </FormControl>
                            <br />
                            <br />
                            <Button
                                disabled={this.state.btnDisabled}
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submitButton}
                                endIcon={<GetAppIcon />}
                                color="primary"
                            >
                                Check Status
                            </Button>
                        </Grid>
                    </form>
                </div>
            </Container>
        );
    };

    render() {
        const contentStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            transition: 'margin-left 1000ms cubic-bezier(0.23, 1, 0.32, 1)',
        };
        const btnStyle = {
            textAlign: 'center',
            marginBottom: '3%',
            marginTop: '3%',
        };
        const InputForm = this.inputForm;
        const Response = this.displayResponse;
        const NewTransactionsBtn = this.checkNewTransactions;
        const storedGroups = JSON.parse(localStorage.getItem('groups'));
        if (storedGroups[2].isMember) {
            // [0] == Admin, [1] == Export, [2] == Import, [3] == Laundry //
            if (this.state.drawerOpen) {
                contentStyle.marginLeft = 256;
            }
            return (
                <div id="returnParent" style={{ zIndex: '97' }}>
                    <SideNav
                        items={menuItems.Sales}
                        callback={this.shiftSideNav}
                    />
                    <div id="contentDiv" style={contentStyle}>
                        <div id="instructionDiv">
                            <br />
                            <Description
                                parentCallBack={
                                    this.state.descriptionAndHelpHandler
                                }
                            />
                        </div>
                        <br />
                        <div
                            id="InputForm"
                            hidden={this.state.dataPosted}
                            style={{ marginTop: '3vh' }}
                        >
                            <InputForm />
                        </div>
                        <div
                            id="progressDiv"
                            style={{ marginTop: '1%', marginBottom: '3%' }}
                            hidden={!this.state.loading}
                        >
                            <BlkCircularProgress />
                        </div>
                        <div id="responseDiv" hidden={!this.state.dataPosted}>
                            {this.state.dataPosted ? <Response /> : <div />}
                        </div>
                        <div
                            id="btn"
                            style={btnStyle}
                            hidden={!this.state.dataPosted}
                        >
                            <NewTransactionsBtn />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h1>
                        YOU DO NOT HAVE ACCESS TO VIEW THE IMPORT PAGE. PLEASE
                        CONTACT YOUR ADMINISTRATOR TO GAIN ACCESS
                    </h1>
                </div>
            );
        }
    }
}

export default withMsal(withStyles(useStyles)(UserImportStatusComponent));
